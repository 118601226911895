define("ember-svg-jar/inlined/na-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g data-name=\"Layer 2\"><g data-name=\"Layer 1\"><path d=\"M38.6 75.74L11 59.81a1.58 1.58 0 010-2.74l28.1-16.23a1.61 1.61 0 011.59 0L69 57.18a1.59 1.59 0 010 2.74L41.6 75.74a3 3 0 01-3 0z\" fill=\"#f2f5f9\"/><path fill=\"#cfd7e2\" d=\"M40.1 41.26L8.64 23.1 39.91 5.04l31.46 18.17L40.1 41.26z\"/><path fill=\"#e9edf4\" d=\"M40.1 41.26L8.64 23.1 39.91 5.04l31.46 18.17L40.1 41.26z\"/><path fill=\"#dce2ea\" d=\"M40.1 41.26L8.64 23.1 39.91 5.04l.19 36.22z\"/><path fill=\"#cfd7e2\" d=\"M40.1 66.33L8.64 48.16l31.27-18.05 31.46 18.17L40.1 66.33z\"/><path fill=\"#e9edf4\" d=\"M40.1 66.33L8.64 48.16V23.1L40.1 41.26v25.07z\"/><path fill=\"#dce2ea\" d=\"M40.1 66.33l31.27-18.05V23.21L40.1 41.26v25.07z\"/><path fill=\"#e9edf4\" d=\"M0 18.05L31.28 0l8.63 5.04L8.64 23.1 0 18.05z\"/><path fill=\"#f2f5f9\" d=\"M79.82 18.05L48.54 0l-8.63 5.04L71.19 23.1l8.63-5.05zM40.1 41.26L8.64 23.1l-8.6 5.04 31.29 18.05 8.77-4.93z\"/><path fill=\"#e9edf4\" d=\"M40.1 41.26l31.27-18.05L80 28.25 48.73 46.31l-8.63-5.05z\"/></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 80 76.14"
    }
  };
  _exports.default = _default;
});