define("ember-svg-jar/inlined/calendar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g class=\"fill-current\" fill=\"#9EAAB2\" fill-rule=\"evenodd\"><path d=\"M12 1h2a1 1 0 011 1v12a1 1 0 01-1 1H1a1 1 0 01-1-1V2a1 1 0 011-1h2v1h2V1h5v1h2V1zM1 4v10h13V4H1zM5 0v1H3V0zM12 0v1h-2V0z\"/><path d=\"M5 5h2v2H5zM5 8h2v2H5zM5 11h2v2H5zM2 8h2v2H2zM2 11h2v2H2zM8 5h2v2H8zM8 8h2v2H8zM8 11h2v2H8zM11 5h2v2h-2zM11 8h2v2h-2z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "15",
      "height": "15",
      "viewBox": "0 0 15 15"
    }
  };
  _exports.default = _default;
});