define("ember-svg-jar/inlined/info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M8 16A8 8 0 108 0a8 8 0 000 16z\" fill=\"#9EAAB2\" class=\"fill-current\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M9.7 11.484c0 .17-.402.57-1.207 1.2s-1.533.944-2.184.944c-.448 0-.773-.115-.976-.344a1.141 1.141 0 01-.304-.776c0-.181.027-.35.08-.504.053-.155.112-.312.176-.472l1.52-3.152c.085-.181.147-.328.184-.44a.953.953 0 00.056-.296c0-.085-.016-.147-.048-.184a.16.16 0 00-.128-.056c-.139 0-.408.21-.808.632-.4.421-.637.632-.712.632-.085 0-.17-.059-.256-.176-.085-.117-.128-.192-.128-.224 0-.107.101-.28.304-.52.203-.24.459-.493.768-.76.277-.245.61-.475 1-.688.39-.213.733-.32 1.032-.32.48 0 .84.136 1.08.408.24.272.36.6.36.984 0 .16-.016.33-.048.512a1.803 1.803 0 01-.208.56l-1.216 2.672a5.753 5.753 0 00-.208.544 1.19 1.19 0 00-.064.288c0 .064.016.104.048.12a.213.213 0 00.096.024c.235 0 .51-.168.824-.504.315-.336.515-.504.6-.504.075 0 .155.056.24.168.085.112.128.19.128.232zM9.4 5.3a1.4 1.4 0 100-2.8 1.4 1.4 0 000 2.8z\" fill=\"#fff\"/>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});