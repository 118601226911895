define("ember-freestyle/templates/components/freestyle-annotation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Q6MTDw1f",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,1],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"showNotes\",\"if\"]}",
    "moduleName": "ember-freestyle/templates/components/freestyle-annotation.hbs"
  });

  _exports.default = _default;
});