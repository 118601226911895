define("ember-freestyle/components/freestyle-collection", ["exports", "ember-freestyle/templates/components/freestyle-collection"], function (_exports, _freestyleCollection) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _freestyleCollection.default,
    classNames: ['FreestyleCollection'],
    classNameBindings: ['inline:FreestyleCollection--inline'],
    emberFreestyle: Ember.inject.service(),
    showLabels: Ember.computed.and('emberFreestyle.notFocused', 'emberFreestyle.showLabels'),
    hasLabels: Ember.computed.and('showLabels', 'title'),
    showVariantList: Ember.computed.not('emberFreestyle.focus'),
    defaultKey: 'all',
    activeKey: Ember.computed('defaultKey', 'key', 'emberFreestyle.focus', function () {
      if (this.get('emberFreestyle.focus')) {
        return 'all';
      }

      return this.get('key') || this.get('defaultKey');
    }),

    registerVariant(variantKey) {
      Ember.run.next(() => {
        if (this.isDestroyed) {
          return;
        }

        let variants = this.get('variants') || Ember.A(['all']);

        if (!variants.includes(variantKey)) {
          variants.pushObject(variantKey);
        }

        this.set('variants', variants);
      });
    },

    actions: {
      setKey(key) {
        this.set('key', key);
      }

    }
  });

  _exports.default = _default;
});