define("ember-svg-jar/inlined/notification-checkmark", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#FFF\" fill-opacity=\".75\" fill-rule=\"evenodd\" d=\"M6.43 9.5L4.11 7.18a2.4 2.4 0 00-3.395 0l-.713.714 4.017 4.017 2.41 2.41 9.642-9.641-.714-.713a2.4 2.4 0 00-3.394 0L6.43 9.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16"
    }
  };
  _exports.default = _default;
});