define("ember-svg-jar/inlined/small-sort", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>small-sort</title><g data-name=\"Layer 2\"><g data-name=\"Layer 1\"><path d=\"M.5 6.55h10a.5.5 0 00.37-.83l-5-5.56a.52.52 0 00-.74 0l-5 5.56a.5.5 0 00.37.83zM10.5 9.43H.5a.5.5 0 00-.37.83l5 5.56a.5.5 0 00.74 0l5-5.56a.5.5 0 00-.37-.83z\"/></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 11 15.99"
    }
  };
  _exports.default = _default;
});