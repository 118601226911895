define("ember-svg-jar/inlined/reminder-disable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M0 0h16v16H0V0z\" fill=\"#fff\"/><path d=\"M4 15h2c.6 0 1-.4 1-1H3c0 .6.4 1 1 1zM6 10.5c0-2.8 2.1-5.1 4.8-5.4-.5-1.4-1.5-2.4-2.8-2.8C7.9 1.6 7.2 1 6.5 1S5.1 1.6 5 2.3c-1.7.6-3 2.2-3 4.2v3.8L1 13h5.6c-.4-.8-.6-1.6-.6-2.5z\" fill=\"#78909C\"/><path d=\"M11.5 6C9 6 7 8 7 10.5S9 15 11.5 15s4.5-2 4.5-4.5S14 6 11.5 6zM8 10.5c0-.8.3-1.5.7-2.1l4.9 4.9c-.6.4-1.3.7-2.1.7C9.6 14 8 12.4 8 10.5zm6.3 2.1L9.4 7.7c.6-.4 1.3-.7 2.1-.7 1.9 0 3.5 1.6 3.5 3.5 0 .8-.3 1.5-.7 2.1z\" fill=\"#78909C\"/>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});