define("ember-svg-jar/inlined/no-access", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"#E57373\" fill-rule=\"evenodd\"><path fill-rule=\"nonzero\" d=\"M4.465 4.465a4.998 4.998 0 000 7.07 4.998 4.998 0 007.07 0 4.998 4.998 0 000-7.07 4.998 4.998 0 00-7.07 0zM2.344 2.344a7.998 7.998 0 0111.312 0 7.998 7.998 0 010 11.312 7.998 7.998 0 01-11.312 0 7.998 7.998 0 010-11.312z\"/><path d=\"M1.99 4.111L4.111 1.99l9.899 9.899-2.121 2.121z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16"
    }
  };
  _exports.default = _default;
});