define("@microstates/ember/-private", ["exports", "@microstates/ember/index"], function (_exports, _index) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.stateFrom = stateFrom;

  function stateFrom(typeOrValue, value) {
    if (typeOrValue && typeOrValue.constructor && typeOrValue.constructor.Type) {
      // already a microstate
      return typeOrValue;
    } else if (typeof typeOrValue === "function") {
      return (0, _index.create)(typeOrValue, value);
    } else {
      return (0, _index.from)(typeOrValue);
    }
  }
});