define("ember-svg-jar/inlined/allovue_logo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" fill=\"#FFF\" d=\"M334.9 432h-238c-53.6 0-97-43.4-97-97V97c0-53.6 43.4-97 97-97h238c53.6 0 97 43.4 97 97v238c0 53.6-43.4 97-97 97z\"/><g fill-rule=\"evenodd\" clip-rule=\"evenodd\"><path fill=\"#D2409B\" d=\"M133.8 432H96.7C43.5 432-.1 388.5-.1 335.2v-.7l133.9-62.3V432z\"/><path fill=\"#23ADCE\" d=\"M279.5 432h-112V256.6l112-52.1z\"/><path fill=\"#BADD1D\" d=\"M431.9 133.6v201.7c0 53.2-43.5 96.8-96.7 96.8h-21.9V188.8l118.6-55.2z\"/><path fill=\"#4A4A4A\" d=\"M96.7 0h238.5c53.2 0 96.7 43.5 96.7 96.7v5.3L-.1 303V96.7C-.1 43.5 43.4 0 96.7 0z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 431.8 432"
    }
  };
  _exports.default = _default;
});