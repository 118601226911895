define("ember-svg-jar/inlined/filters", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g class=\"stroke-current\" fill=\"#231f20\"><path d=\"M12.5.5C5.883.5.5 5.883.5 12.5s5.383 12 12 12 12-5.383 12-12-5.383-12-12-12zm0 23c-6.065 0-11-4.935-11-11s4.935-11 11-11 11 4.935 11 11-4.935 11-11 11z\"/><path d=\"M19 8.5h-3.5v-1a1 1 0 00-2 0v1H6a.5.5 0 000 1h7.5v1a1 1 0 002 0v-1H19a.5.5 0 000-1zM19 15.5h-7.5v-1a1 1 0 00-2 0v1H6a.5.5 0 000 1h3.5v1a1 1 0 002 0v-1H19a.5.5 0 000-1z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 25 25"
    }
  };
  _exports.default = _default;
});