define("ember-freestyle/components/freestyle-notes", ["exports", "ember-freestyle/templates/components/freestyle-notes"], function (_exports, _freestyleNotes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _freestyleNotes.default,
    tagName: '',
    emberFreestyle: Ember.inject.service(),
    unindent: true,
    _unindent: function (snippet) {
      if (!this.get('unindent')) {
        return snippet;
      }

      let match,
          min,
          lines = snippet.split("\n").filter(l => l !== '');

      for (let i = 0; i < lines.length; i++) {
        match = /^[ \t]*/.exec(lines[i]);

        if (match && (typeof min === 'undefined' || min > match[0].length)) {
          min = match[0].length;
        }
      }

      if (typeof min !== 'undefined' && min > 0) {
        snippet = snippet.replace(new RegExp("^[ \t]{" + min + "}", 'gm'), "");
      }

      return snippet;
    },
    text: Ember.computed('name', function () {
      return this._unindent((this.get('emberFreestyle.snippets')[this.get('name')] || '').replace(/^(\s*\n)*/, '').replace(/\s*$/, ''));
    })
  });

  _exports.default = _default;
});