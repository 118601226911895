define("ember-light-table/templates/components/lt-scaffolding-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "t+S1N61E",
    "block": "{\"symbols\":[\"column\"],\"statements\":[[6,[37,5],[[30,[36,4],[[30,[36,4],[[35,3]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"td\"],[15,5,[30,[36,2],[[30,[36,1],[[32,1,[\"width\"]],[30,[36,0],[\"width: \",[32,1,[\"width\"]]],null]],null]],null]],[14,0,\"lt-scaffolding\"],[12],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"concat\",\"if\",\"html-safe\",\"columns\",\"-track-array\",\"each\"]}",
    "moduleName": "ember-light-table/templates/components/lt-scaffolding-row.hbs"
  });

  _exports.default = _default;
});