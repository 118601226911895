define("ember-svg-jar/inlined/sort", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g class=\"stroke-current\" fill=\"#231f20\"><path d=\"M6.57 3.032c-.004-.005-.011-.006-.015-.011-.092-.101-.221-.168-.368-.168s-.277.066-.369.168c-.004.005-.011.006-.015.011L.617 9.233a.499.499 0 10.766.642L5.687 4.73v16.917a.5.5 0 001 0V4.73l4.299 5.139a.498.498 0 00.704.062.499.499 0 00.062-.704L6.57 3.032zM24.321 15.063a.5.5 0 00-.704.062l-4.303 5.144V3.353a.5.5 0 00-1 0V20.27l-4.299-5.139a.5.5 0 00-.766.642l5.183 6.195c.004.005.011.007.016.012a.502.502 0 00.118.091c.016.01.029.024.047.031.062.028.131.045.203.045s.141-.017.203-.045c.017-.008.03-.022.047-.031a.496.496 0 00.118-.091c.005-.005.011-.007.016-.012l5.187-6.201a.503.503 0 00-.066-.704z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 25 25"
    }
  };
  _exports.default = _default;
});