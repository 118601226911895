define("ember-svg-jar/inlined/form-chevron", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>Fill 1024</title><path d=\"M16.4 1.2c.3-.3.3-.7 0-1-.3-.3-.8-.3-1.1 0l-7 6.7-7-6.7C1-.1.5-.1.2.2c-.3.3-.3.7 0 1l7 6.7 1.1 1 1.1-1 7-6.7z\" fill=\"#007dbf\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 17 9"
    }
  };
  _exports.default = _default;
});