define("ember-macro-helpers/flatten-keys-unsafe", ["exports", "ember-macro-helpers/flatten-keys"], function (_exports, _flattenKeys) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(keys) {
    let flattenedKeys = (0, _flattenKeys.default)(keys);
    return flattenedKeys.reduce((flattenedKeys, key) => {
      // keys with spaces throw an exception
      // treat as a literal and ignore
      let hasSpaces = key.indexOf(' ') !== -1;

      if (!hasSpaces) {
        flattenedKeys.push(key);
      }

      return flattenedKeys;
    }, []);
  }
});