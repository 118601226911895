define("ember-svg-jar/inlined/show-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>show-details</title><g data-name=\"Layer 2\"><g data-name=\"Layer 1\"><path d=\"M2.45 22.62H1.32A.32.32 0 011 22.3V5.18a.32.32 0 01.32-.32h3.07a.5.5 0 000-1H1.32A1.32 1.32 0 000 5.18V22.3a1.32 1.32 0 001.32 1.32h15.49a1.32 1.32 0 001.32-1.32V10.81a.5.5 0 00-1 0V22.3a.32.32 0 01-.32.32h-1.13m-1 0h-1.45m-1 0h-1.45m-1 0H8.34m-1 0H5.89m-1 0H3.45\" fill=\"#231f20\"/><path d=\"M13.63 15.87H3.94a.5.5 0 000 1h9.7a.5.5 0 000-1zM13.63 18.87H3.94a.5.5 0 100 1h9.7a.5.5 0 000-1zM9.06 12.87H3.94a.5.5 0 000 1h5.12a.5.5 0 000-1z\"/><circle cx=\"11.9\" cy=\"5.9\" r=\"5.4\" fill=\"none\" stroke=\"#231f20\" stroke-miterlimit=\"10\"/><path d=\"M11.19 5.68v3.17a.71.71 0 001.41 0V5.68a.71.71 0 00-1.41 0zM11.15 3.42a.75.75 0 001.5 0 .75.75 0 00-1.5 0z\"/></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 18.12 23.62"
    }
  };
  _exports.default = _default;
});