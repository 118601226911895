define("@microstates/ember/initializers/microstates", ["exports", "@microstates/ember/index"], function (_exports, _index) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(application) {
    application.register('type:any', _index.Any);
  }

  var _default = {
    initialize
  };
  _exports.default = _default;
});