define("ember-freestyle/templates/components/freestyle-dynamic-input-select-option", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eUkURe+g",
    "block": "{\"symbols\":[],\"statements\":[[1,[34,0]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"optionValue\"]}",
    "moduleName": "ember-freestyle/templates/components/freestyle-dynamic-input-select-option.hbs"
  });

  _exports.default = _default;
});