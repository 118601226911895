define("ember-svg-jar/inlined/admin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path class=\"fill-current\" fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M3.145 4.474l-1.887-.782A8.044 8.044 0 00.145 6.478l1.9.787a5.962 5.962 0 00.028 1.675L.186 9.72a8.052 8.052 0 001.183 2.757l1.9-.786c.327.42.705.79 1.122 1.102l-.784 1.893a8.044 8.044 0 002.771 1.148l.784-1.893c.55.078 1.113.08 1.676 0l.784 1.893a8.045 8.045 0 002.772-1.148l-.784-1.892c.426-.32.801-.693 1.121-1.103l1.9.786a8.045 8.045 0 001.182-2.757l-1.886-.781c.087-.548.1-1.112.03-1.675l1.898-.787a8.037 8.037 0 00-1.113-2.786l-1.886.781a5.963 5.963 0 00-1.247-1.267l.784-1.893A8.045 8.045 0 009.622.165l-.784 1.893a5.964 5.964 0 00-1.676 0L6.378.164a8.043 8.043 0 00-2.772 1.148l.784 1.892c-.482.363-.9.792-1.245 1.269zm6.003 6.298a3 3 0 10-2.296-5.543 3 3 0 002.296 5.543z\" fill=\"#9EAAB2\"/>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});