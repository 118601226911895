define("ember-svg-jar/inlined/home", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M24 22.016h-1.5V10.415l.029.016c.159.058.321.085.481.085.6 0 1.172-.384 1.396-.997.279-.765-.085-1.607-.815-1.894L12.729 2.04a.504.504 0 00-.457 0L1.441 7.612C.691 7.887.31 8.742.594 9.518a1.484 1.484 0 001.906.893v11.605H1a.5.5 0 000 1h23a.5.5 0 000-1zM1.534 9.175a.495.495 0 01.308-.649L12.5 3.046l10.658 5.48c.019.009.037.018.057.025.233.085.346.365.251.624-.094.259-.365.401-.53.344l-10.2-5.454a.495.495 0 00-.472 0L2.128 9.491c-.232.083-.5-.056-.594-.316zM10.5 22.016v-7h4v7h-4zm-1-8v8h-6V9.885l9-4.812 9 4.807v12.135h-6v-8l-6 .001z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 25 25"
    }
  };
  _exports.default = _default;
});