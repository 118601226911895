define("ember-svg-jar/inlined/segment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"#90A4AE\" class=\"fill-current\"><path d=\"M0 1h1v2H0zM3 1h1v2H3zM1 1V0h2v1zM0 4h1v2H0zM3 4h1v2H3zM1 4V3h2v1zM1 7V6h2v1zM5 1h1v2H5zM8 1h1v2H8zM6 1V0h2v1zM5 4h1v2H5zM8 4h1v2H8zM6 4V3h2v1zM6 7V6h2v1zM10 1h1v2h-1zM13 1h1v2h-1zM11 1V0h2v1zM13 4h1v2h-1zM10 4h1v2h-1zM11 4V3h2v1zM11 7V6h2v1z\"/></g>",
    "attrs": {
      "width": "14",
      "height": "7",
      "viewBox": "0 0 14 7",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});