define("ember-svg-jar/inlined/reset", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M19.518 2.856l4.379.919c.27.061.535-.116.592-.387s-.116-.535-.387-.592l-6.135-1.287a.5.5 0 00-.577.647l1.998 5.989a.5.5 0 10.948-.316l-1.384-4.152a10.865 10.865 0 014.547 8.822c0 5.603-4.368 10.355-9.945 10.82a.5.5 0 00.041.998l.042-.002c6.092-.506 10.863-5.697 10.863-11.816 0-3.853-1.886-7.432-4.982-9.643zM11.618.684C5.542 1.204.783 6.394.783 12.499c0 3.713 1.691 7.112 4.574 9.349l-4.259-.855a.5.5 0 00-.197.981l6.036 1.213a.5.5 0 00.584-.611l-1.495-5.97a.499.499 0 10-.969.242l1.084 4.33A10.758 10.758 0 011.784 12.5c0-5.59 4.357-10.343 9.92-10.819a.5.5 0 00.456-.541.507.507 0 00-.542-.456z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 25 25"
    }
  };
  _exports.default = _default;
});