define("ember-svg-jar/inlined/add-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g class=\"fill-current\" fill-rule=\"evenodd\"><circle cx=\"9.706\" cy=\"2.647\" r=\"2.647\"/><path d=\"M4.713 9.706a5.296 5.296 0 019.986 0H4.713zM5.735 4.853H.441v1h5.294z\"/><path d=\"M3.529 2.647v5.294h-1V2.647z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "15",
      "height": "10",
      "viewBox": "0 0 15 10"
    }
  };
  _exports.default = _default;
});