define("ember-svg-jar/inlined/notification-error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path class=\"fill-current\" fill=\"#FFF\" fill-opacity=\".75\" fill-rule=\"evenodd\" d=\"M8 16A8 8 0 118 0a8 8 0 010 16zM8 2.5a1.5 1.5 0 00-1.496 1.607l.346 4.84a1.153 1.153 0 002.3 0l.346-4.84A1.5 1.5 0 008 2.5zm0 10.74a1.074 1.074 0 100-2.148 1.074 1.074 0 000 2.148z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16"
    }
  };
  _exports.default = _default;
});