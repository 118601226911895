define("ember-freestyle/components/freestyle-dynamic", ["exports", "ember-freestyle/templates/components/freestyle-dynamic"], function (_exports, _freestyleDynamic) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _freestyleDynamic.default,
    classNames: ['FreestyleDynamic'],
    headerTitle: 'Dynamic Properties:',
    // Need this separate property for freestyle-dynamic's dynamic.<property> to work
    dynamicPropertyValues: Ember.computed('dynamicProperties', function () {
      let dynamicPropertyValues = {};
      const dynamicProperties = this.get('dynamicProperties');
      Object.keys(dynamicProperties).forEach(propertyName => {
        dynamicPropertyValues[propertyName] = Ember.get(dynamicProperties, `${propertyName}.value`);
      });
      return dynamicPropertyValues;
    }),

    init() {
      this._super(...arguments);

      let dynamicProperties = this.get('dynamicProperties');

      if (dynamicProperties) {
        (false && !(typeof dynamicProperties === 'object') && Ember.assert(`dynamicProperties passed into freestyle-dynamic must be an object.  You passed: ${dynamicProperties}`, typeof dynamicProperties === 'object'));
      } else {
        this.dynamicProperties = {};
      }
    },

    actions: {
      changeValueTo(property, newValue) {
        // Make a copy and then reset dynamicProperties, to force template changes
        let dynamicProperties = Ember.assign({}, this.get('dynamicProperties'));
        Ember.set(dynamicProperties, `${property}.value`, newValue);
        this.set('dynamicProperties', dynamicProperties);
      }

    }
  }).reopenClass({
    positionalParams: ['slug']
  });

  _exports.default = _default;
});