define("ember-file-upload/services/file-queue", ["exports", "ember-file-upload/queue", "ember-file-upload/mixins/with-files"], function (_exports, _queue, _withFiles) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  /**
    The file queue service is a global file
    queue that manages all files being uploaded.
  
    This service can be used to query the current
    upload state when a user leaves the app,
    asking them whether they want to cancel
    the remaining uploads.
  
    @class FileQueue
    @extends Ember.Service
   */
  class FileQueueService extends Ember.Service.extend(_withFiles.default) {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "queues", Ember.A([]));

      _defineProperty(this, "files", Ember.A([]));
    }

    /**
      Returns a queue with the given name
       @method find
      @param {String} name The name of the queue to find
      @return {Queue} The queue or null if it doesn't exist yet.
     */
    find(name) {
      return this.queues.findBy('name', name);
    }
    /**
      Create a new queue with the given name.
       @method create
      @param {String} name The name of the queue to create
      @return {Queue} The new queue.
     */


    create(name) {
      (false && !(this.find(name) == null) && Ember.assert(`Queue names are required to be unique. "${name}" has already been reserved.`, this.find(name) == null));

      let queue = _queue.default.create({
        name,
        fileQueue: this
      });

      this.queues.push(queue);
      Ember.run.once(this, 'notifyPropertyChange', 'queues');
      return queue;
    }

  }

  _exports.default = FileQueueService;
});