define("ember-svg-jar/inlined/lock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M8 3a3 3 0 00-3 3v1H4v6h8V7h-1V6a3 3 0 00-3-3zm1 4V6a1 1 0 00-2 0v1h2z\" class=\"fill-current\" fill=\"#9EAAB2\"/>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});