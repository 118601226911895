define("ember-svg-jar/inlined/circle-plus-solid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" d=\"M8 16A8 8 0 118 0a8 8 0 010 16zM7 7H4a1 1 0 100 2h3v3a1 1 0 002 0V9h3a1 1 0 000-2H9V4a1 1 0 10-2 0v3z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "#9EAAB2"
    }
  };
  _exports.default = _default;
});