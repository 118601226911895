define("@microstates/ember/helpers/value-of", ["exports", "@microstates/ember/index"], function (_exports, _index) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(([object]) => (0, _index.valueOf)(object));

  _exports.default = _default;
});