define("ember-light-table/utils/css-styleify", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = cssStyleify;

  function cssStyleify(hash = {}) {
    let styles = [];
    Object.keys(hash).forEach(key => {
      let value = hash[key];

      if (Ember.isPresent(value)) {
        styles.push(`${Ember.String.dasherize(key)}: ${value}`);
      }
    });
    return Ember.String.htmlSafe(styles.join('; '));
  }
});