define("ember-svg-jar/inlined/supplement", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"#90A4AE\" class=\"fill-current\"><path d=\"M2 6h1v1H2zM4 6h3v1H4zM2 8h1v1H2zM4 8h3v1H4zM2 10h1v1H2zM4 10h3v1H4z\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M15 1H0v13h15V1zM1 4h13v9H1V4z\"/><path d=\"M8 6h1v1H8zM10 6h3v1h-3zM8 8h1v1H8zM10 8h3v1h-3zM8 10h1v1H8zM10 10h3v1h-3z\"/></g>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});