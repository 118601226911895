define("ember-svg-jar/inlined/na-benefits", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g data-name=\"Layer 2\"><g data-name=\"Layer 1\"><ellipse cx=\"43.11\" cy=\"53\" rx=\"16.79\" ry=\"29.08\" transform=\"rotate(-89.69 43.105 53.002)\" fill=\"#f2f5f9\"/><ellipse cx=\"45.6\" cy=\"39.71\" rx=\"18.71\" ry=\"32.4\" transform=\"rotate(-89.69 45.6 39.715)\" fill=\"#dce2ea\"/><path fill=\"#dce2ea\" d=\"M13.23 34.67h64.74v5.46H13.23z\"/><ellipse cx=\"45.6\" cy=\"35.1\" rx=\"18.71\" ry=\"32.4\" transform=\"rotate(-89.4 45.784 35.076)\" fill=\"#e9edf4\"/><ellipse cx=\"45.6\" cy=\"35.1\" rx=\"17.06\" ry=\"29.56\" transform=\"rotate(-89.69 45.598 35.102)\" fill=\"#dce2ea\"/><ellipse cx=\"45.6\" cy=\"35.1\" rx=\"15.44\" ry=\"26.74\" transform=\"rotate(-89.69 45.598 35.102)\" fill=\"#e9edf4\"/><ellipse cx=\"32.4\" cy=\"31.52\" rx=\"18.71\" ry=\"32.4\" transform=\"rotate(-89.69 32.403 31.518)\" fill=\"#dce2ea\"/><path fill=\"#dce2ea\" d=\"M.03 26.48h64.74v5.46H.03z\"/><ellipse cx=\"32.4\" cy=\"26.9\" rx=\"18.71\" ry=\"32.4\" transform=\"rotate(-89.69 32.4 26.906)\" fill=\"#e9edf4\"/><ellipse cx=\"32.4\" cy=\"26.9\" rx=\"17.06\" ry=\"29.56\" transform=\"rotate(-89.69 32.4 26.906)\" fill=\"#dce2ea\"/><ellipse cx=\"32.4\" cy=\"26.9\" rx=\"15.44\" ry=\"26.74\" transform=\"rotate(-89.69 32.4 26.906)\" fill=\"#e9edf4\"/><ellipse cx=\"45.6\" cy=\"23.32\" rx=\"18.71\" ry=\"32.4\" transform=\"rotate(-89.69 45.597 23.32)\" fill=\"#dce2ea\"/><path fill=\"#dce2ea\" d=\"M13.23 18.28h64.74v5.46H13.23z\"/><ellipse cx=\"45.6\" cy=\"18.71\" rx=\"18.71\" ry=\"32.4\" transform=\"rotate(-89.69 45.594 18.708)\" fill=\"#e9edf4\"/><ellipse cx=\"45.6\" cy=\"18.71\" rx=\"17.06\" ry=\"29.56\" transform=\"rotate(-89.69 45.594 18.708)\" fill=\"#dce2ea\"/><ellipse cx=\"45.6\" cy=\"18.71\" rx=\"15.44\" ry=\"26.74\" transform=\"rotate(-89.69 45.594 18.708)\" fill=\"#e9edf4\"/><path fill=\"#dce2ea\" d=\"M49.55 16.46l9.51 1-8.5 2.61 1.35 5.53-6.99-3.87-8.68 2.41 4.18-5.01-6.71-4.04 9.57.78 4.53-4.91 1.74 5.5z\"/></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 78 69.79"
    }
  };
  _exports.default = _default;
});