define("ember-svg-jar/inlined/tag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path class=\"fill-current\" fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M13.2 7.6L5.9.3C5.7.1 5.4 0 5.1 0L1.3.4C.8.5.5.8.4 1.3L0 5.1c0 .3.1.6.3.8l7.3 7.3c.4.4 1 .4 1.4 0L13.2 9c.4-.4.4-1 0-1.4zM4 4c-.3.4-1 .4-1.4 0-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0 .4.4.4 1.1 0 1.4z\" fill=\"#607D8B\"/>",
    "attrs": {
      "width": "14",
      "height": "14",
      "viewBox": "0 0 14 14",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});