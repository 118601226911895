define("ember-freestyle/templates/components/freestyle-dynamic", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9CV5D4DN",
    "block": "{\"symbols\":[\"propertyHash\",\"propertyName\",\"&default\"],\"statements\":[[6,[37,7],null,null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"h3\"],[14,0,\"FreestyleDynamic-header\"],[12],[1,[34,3]],[13],[2,\"\\n  \"],[10,\"form\"],[14,0,\"FreestyleDynamic-form\"],[12],[2,\"\\n\"],[6,[37,6],[[30,[36,5],[[35,4]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[1,[30,[36,2],null,[[\"propertyName\",\"value\",\"options\",\"inputType\",\"description\",\"min\",\"max\",\"changeValueTo\"],[[32,2],[32,1,[\"value\"]],[32,1,[\"options\"]],[32,1,[\"inputType\"]],[32,1,[\"description\"]],[32,1,[\"min\"]],[32,1,[\"max\"]],[30,[36,1],[[32,0],\"changeValueTo\",[32,2]],null]]]]],[2,\"\\n\"]],\"parameters\":[1,2]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,11],[[35,10]],[[\"title\",\"isDynamic\",\"componentName\",\"dynamicProperties\"],[[35,9],true,[35,8],[35,4]]],[[\"default\"],[{\"statements\":[[2,\"  \"],[18,3,[[35,0]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"dynamicPropertyValues\",\"action\",\"freestyle-dynamic-input\",\"headerTitle\",\"dynamicProperties\",\"-each-in\",\"each\",\"freestyle-annotation\",\"componentName\",\"title\",\"slug\",\"freestyle-usage\"]}",
    "moduleName": "ember-freestyle/templates/components/freestyle-dynamic.hbs"
  });

  _exports.default = _default;
});