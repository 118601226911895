define("ember-svg-jar/inlined/dropdown-bg-error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><path fill=\"#819299\" d=\"M16.657 17.485l3.535-3.535a1 1 0 111.415 1.414l-4.243 4.243a.997.997 0 01-1.414 0l-4.243-4.243a1 1 0 011.414-1.414l3.536 3.535z\"/><path fill=\"#E57373\" d=\"M0 0h1v30H0z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "33",
      "height": "30",
      "viewBox": "0 0 33 30"
    }
  };
  _exports.default = _default;
});