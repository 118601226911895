define("ember-svg-jar/inlined/search-magnifying", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>search-magnifying</title><g data-name=\"Layer 2\"><g data-name=\"Layer 1\"><path d=\"M4.5 1A3.5 3.5 0 111 4.5 3.5 3.5 0 014.5 1m0-1A4.5 4.5 0 109 4.5 4.5 4.5 0 004.5 0z\" fill=\"#231f20\"/><path fill=\"none\" stroke=\"#231f20\" stroke-linecap=\"round\" stroke-linejoin=\"round\" d=\"M7.59 7.59L10 10\"/></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 10.5 10.5"
    }
  };
  _exports.default = _default;
});