define("ember-svg-jar/inlined/group-by", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g class=\"fill-current\" fill=\"#231f20\"><circle cx=\"21.5\" cy=\"3.5\" r=\"2.5\"/><circle cx=\"3.5\" cy=\"3.5\" r=\"2.5\"/><circle cx=\"12.5\" cy=\"3.5\" r=\"2.5\"/><path d=\"M24.446 8.257a.5.5 0 00-.671-.223c-.039.021-4.025 1.979-10.775 1.979-6.751 0-10.769-1.959-10.809-1.979a.5.5 0 00-.446.894c.162.082 3.866 1.89 10.255 2.067v5.555c-1.968.25-3.5 1.916-3.5 3.95 0 2.206 1.794 4 4 4s4-1.794 4-4c0-2.034-1.532-3.7-3.5-3.949v-5.538c7.007 0 11.056-2 11.225-2.085a.5.5 0 00.221-.671zM15.5 20.5c0 1.654-1.346 3-3 3s-3-1.346-3-3 1.346-3 3-3 3 1.346 3 3z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 25 25"
    }
  };
  _exports.default = _default;
});