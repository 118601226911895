define("ember-file-upload/helpers/file-queue", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  /**
    `file-queue` helper is one of the core primitives of ember-file-upload.
    It has three different flavors of invocation. The first is
    a bare call, which will return the master queue:
  
    ```hbs
    {{#with (file-queue) as |queue|}}
      {{queue.progress}}%
    {{/with}}
    ```
  
    If called with a `name` property, it will return the queue
    of that name:
  
    ```hbs
    {{#with (file-queue name="photos") as |queue|}}
      {{queue.progress}}%
    {{/with}}
    ```
  
    @class FileQueue
    @param {string} [name] The name of the queue to get information on
    @return {Queue} A collection of all queues, or a specific queue.
   */
  let FileQueueHelper = (_dec = Ember.inject.service('file-queue'), (_class = class FileQueueHelper extends Ember.Helper {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "fileQueue", _descriptor, this);
    }

    compute(_, hash) {
      let queues = this.fileQueue;
      let queueName = hash['name'];

      if (queueName) {
        let queue = queues.find(queueName) || queues.create(queueName);
        return queue;
      }

      return queues;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "fileQueue", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = FileQueueHelper;
});