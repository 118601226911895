define("ember-svg-jar/inlined/dollar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"#90A4AE\" class=\"fill-current\"><path d=\"M6 4.6v1h2v-1H6zM7 3h1a1 1 0 00-1-1v1zM1 3V2a1 1 0 00-1 1h1zm0 3.2H0a1 1 0 00.742.966L1 6.2zm6 1.6h1a1 1 0 00-.742-.966L7 7.8zM7 11v1a1 1 0 001-1H7zm-6 0H0a1 1 0 001 1v-1zm1-1.6v-1H0v1h2zm6-4.8V3H6v1.6h2zM7 2H1v2h6V2zM0 3v3.2h2V3H0zm.742 4.166l6 1.6.516-1.932-6-1.6-.516 1.932zM6 7.8V11h2V7.8H6zM7 10H1v2h6v-2zm-5 1V9.4H0V11h2z\"/><path class=\"stroke-current\" d=\"M3.5.5h1v1h-1zM3.5 12.5h1v1h-1z\"/></g>",
    "attrs": {
      "width": "8",
      "height": "14",
      "viewBox": "0 0 8 14",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});