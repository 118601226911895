define("ember-svg-jar/inlined/district-cancel1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"#9EAAB2\" fill-rule=\"evenodd\"><path d=\"M3 8h4V7H3v1zm6-3h4V4H9v1zm-2.367 9H3v-1h3.238a5.495 5.495 0 01-.185-2H3v-1h3.238a5.437 5.437 0 011.375-2.39C9.636 5.588 12.841 5.498 15 7.291V2a1 1 0 00-1-1H9a1 1 0 00-1 1v2H3a1 1 0 00-1 1v10H.5a.5.5 0 000 1h7.857a5.684 5.684 0 01-.744-.611A5.412 5.412 0 016.633 14zm9.046 1.036c-.098.116-.178.243-.288.353-.23.23-.483.427-.744.611h.853a.497.497 0 00.179-.964\"/><path d=\"M9.417 8.707a3.484 3.484 0 012.085-.706c.896 0 1.793.342 2.475 1.023 1.243 1.245 1.34 3.191.318 4.561L9.417 8.707zm-.39 5.268a3.496 3.496 0 01-.317-4.561l4.876 4.876c-1.35.983-3.357.887-4.559-.315zm5.657-5.658a4.506 4.506 0 00-6.364 0 4.506 4.506 0 000 6.365A4.47 4.47 0 0011.502 16a4.47 4.47 0 003.182-1.318 4.507 4.507 0 000-6.365z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16"
    }
  };
  _exports.default = _default;
});