define("ember-svg-jar/inlined/collapse", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<defs><style>.cls-1{fill:#231f20}</style></defs><title>collapse</title><g id=\"Layer_2\" data-name=\"Layer 2\"><g id=\"Layer_1-2\" data-name=\"Layer 1\"><path class=\"cls-1\" d=\"M7.88 10.18a.49.49 0 00-.74 0l-2.5 3a.5.5 0 10.77.64L7 11.88v4.62a.5.5 0 001 0v-4.62l1.62 1.94a.5.5 0 10.77-.64zM7.12 6.82a.49.49 0 00.12.09.48.48 0 00.46.09.49.49 0 00.12-.09l2.5-3a.5.5 0 10-.77-.64L8 5.12V.5a.5.5 0 00-1 0v4.62L5.38 3.17a.5.5 0 10-.77.64zM14.5 8H.5a.5.5 0 000 1h14a.5.5 0 000-1z\"/></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 15 17"
    }
  };
  _exports.default = _default;
});