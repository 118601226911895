define("ember-svg-jar/inlined/encumbered-square", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g clip-path=\"url(#clip0)\"><path fill=\"#536DFE\" d=\"M0 0h16v16H0z\"/><path fill=\"#4DD0E1\" d=\"M16.537-65.759l828.114 828.114-2.121 2.121L14.416-63.638zM13.002-62.223l828.114 828.114-2.122 2.121L10.88-60.102zM9.466-58.688L837.58 769.426l-2.121 2.121L7.345-56.567zM5.93-55.152l828.115 828.114-2.122 2.121L3.81-53.03zM2.395-51.617L830.51 776.497l-2.121 2.121L.274-49.496zM-1.141-48.081l828.114 828.114-2.121 2.121L-3.262-45.96zM-4.676-44.546l828.114 828.114-2.121 2.121L-6.797-42.425zM-8.212-41.01l828.114 828.114-2.121 2.121L-10.333-38.889zM-11.747-37.475L816.367 790.64l-2.121 2.121L-13.868-35.353zM-15.283-33.94l828.114 828.115-2.121 2.121L-17.404-31.818zM-18.818-30.404L809.296 797.71l-2.121 2.121L-20.94-28.282zM-22.354-26.868L805.76 801.246l-2.121 2.121L-24.475-24.747zM-25.89-23.332l828.115 828.114-2.122 2.121L-28.01-21.21zM-29.425-19.797L798.69 808.317l-2.121 2.121L-31.546-17.676zM-32.96-16.262l828.114 828.114-2.121 2.122L-35.081-14.14zM-36.496-12.726l828.114 828.114-2.121 2.121L-38.617-10.605zM-40.032-9.19l828.114 828.114-2.121 2.12L-42.153-7.068zM-43.567-5.655l828.114 828.114-2.121 2.121L-45.688-3.534zM-47.102-2.119l828.114 828.114-2.122 2.122L-49.224.003zM-50.638 1.416L777.476 829.53l-2.121 2.121L-52.76 3.537zM-54.174 4.952L773.94 833.066l-2.121 2.121L-56.295 7.073zM-57.709 8.487l828.114 828.114-2.121 2.121L-59.83 10.608zM-61.245 12.023l828.114 828.114-2.121 2.121L-63.366 14.144zM-64.78 15.558l828.114 828.114-2.121 2.121L-66.901 17.68z\"/></g><defs><clipPath id=\"clip0\"><path fill=\"#fff\" d=\"M0 0h16v16H0z\"/></clipPath></defs>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});