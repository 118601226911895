define("ember-macro-helpers/expand-property-list", ["exports", "ember-macro-helpers/expand-property"], function (_exports, _expandProperty) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(propertyList) {
    return propertyList.reduce((newPropertyList, property) => {
      return newPropertyList.concat((0, _expandProperty.default)(property));
    }, []);
  }
});