define("@microstates/ember/helpers/type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    compute([name]) {
      let factory = Ember.getOwner(this).factoryFor(`type:${name}`);
      (false && !(factory && factory.class) && Ember.assert(`(type "${name}") could not be looked up`, factory && factory.class));
      return factory.class;
    }

  });

  _exports.default = _default;
});