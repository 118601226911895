define("ember-svg-jar/inlined/download-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><path class=\"fill-white\" stroke=\"#9EAAB2\" d=\"M.5 4.111V15.5h14V4.111L12.807.5H2.193L.5 4.111z\"/><path fill=\"#9EAAB2\" d=\"M15 5H0l1.875-5h11.25z\"/><circle cx=\"7.5\" cy=\"4.5\" r=\"1.5\" fill=\"#9EAAB2\"/><g fill=\"#FFF\"><path d=\"M7.5 12h1V7h-1z\"/><path d=\"M10.829 10l-.707-.707-2.829 2.828.707.707z\"/><path d=\"M5.879 9.293L5.172 10 8 12.828l.707-.707z\"/></g><g fill=\"#9EAAB2\"><path d=\"M7 12h1V7H7z\"/><path d=\"M10.329 10l-.707-.707-2.829 2.828.707.707z\"/><path d=\"M5.379 9.293L4.672 10 7.5 12.828l.707-.707z\"/></g><path fill=\"#FFF\" d=\"M4 13h8v1H4z\"/><path fill=\"#9EAAB2\" d=\"M4 13h7v1H4z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16"
    }
  };
  _exports.default = _default;
});