define("ember-svg-jar/inlined/file-xlsx", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\" transform=\"translate(9)\"><path stroke=\"#9EAAB2\" d=\"M24.5 6.047L18.689.5H4a.5.5 0 00-.5.5v26a.5.5 0 00.5.5h20a.5.5 0 00.5-.5V6.047z\"/><path stroke=\"#9EAAB2\" stroke-linecap=\"round\" stroke-linejoin=\"round\" d=\"M23 7h-5V2\"/><rect width=\"23\" height=\"10\" y=\"12\" fill=\"#9EAAB2\" rx=\"1\"/><path fill=\"#FFF\" d=\"M2.605 14.182l1.074 2.034c.023.043.031.057.031.102h.031c0-.045 0-.06.023-.102l1.083-2.034h1.247L4.457 17.09 6.122 20H4.861l-1.097-2.048c-.023-.049-.023-.066-.023-.111h-.03c0 .045-.01.062-.032.11L2.582 20H1.321l1.665-2.91-1.634-2.908h1.253zm7.86 4.889V20H6.919v-5.818h1.105v4.889h2.443zm3.887-3.241c-.05-.5-.403-.793-1.02-.793-.602 0-1.017.284-1.017.696 0 .4.426.597 1.103.815l.31.1c1.096.355 1.704.83 1.704 1.718 0 1.023-.798 1.722-2.134 1.722-1.17 0-2.199-.61-2.221-1.852h1.08c.042.605.508.92 1.167.92.716 0 1.042-.372 1.042-.77 0-.412-.332-.65-1.19-.914l-.324-.094c-.946-.281-1.61-.807-1.61-1.642 0-.977.897-1.634 2.099-1.634 1.207 0 2.043.688 2.062 1.728h-1.05zm3.043-1.648l1.074 2.034c.022.043.031.057.031.102h.031c0-.045 0-.06.023-.102l1.082-2.034h1.248l-1.637 2.909L20.912 20H19.65l-1.097-2.048c-.023-.049-.023-.066-.023-.111H18.5c0 .045-.009.062-.031.11L17.372 20h-1.261l1.665-2.91-1.634-2.908h1.253z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "36",
      "height": "28",
      "viewBox": "0 0 36 28"
    }
  };
  _exports.default = _default;
});