define("ember-one-way-select/components/one-way-select", ["exports", "ember-one-way-select/templates/components/one-way-select"], function (_exports, _oneWaySelect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const OneWaySelectComponent = Ember.Component.extend({
    layout: _oneWaySelect.default,
    tagName: 'select',
    // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
    NON_ATTRIBUTE_BOUND_PROPS: ['value', 'update', 'options', 'paramValue', 'prompt', 'promptIsSelectable', 'includeBlank', 'optionValuePath', 'optionLabelPath', 'optionComponent', 'groupLabelPath', 'class', 'classNames'],
    attributeBindings: ['multiple'],

    didReceiveAttrs() {
      this._super(...arguments);

      let newAttributeBindings = []; // eslint-disable-next-line ember/no-attrs-in-components

      for (let key in this.attrs) {
        if (this.NON_ATTRIBUTE_BOUND_PROPS.indexOf(key) === -1 && this.attributeBindings.indexOf(key) === -1) {
          newAttributeBindings.push(key);
        }
      }

      Ember.set(this, 'attributeBindings', this.attributeBindings.concat(newAttributeBindings));
      let value = Ember.get(this, 'paramValue');

      if (value === undefined) {
        value = Ember.get(this, 'value');
      }

      Ember.set(this, 'selectedValue', value);
      let options = Ember.get(this, 'options');

      if (typeof options === 'string') {
        options = Ember.String.w(options);
      }

      let firstOption = Ember.get(Ember.A(options), 'firstObject');

      if (firstOption && Ember.isPresent(Ember.get(firstOption, 'groupName')) && Ember.isArray(Ember.get(firstOption, 'options'))) {
        Ember.set(this, 'optionsArePreGrouped', true);
      }

      if (Ember.isBlank(Ember.get(this, 'promptIsSelectable'))) {
        Ember.set(this, 'promptIsSelectable', false);
      }

      Ember.set(this, 'options', Ember.A(options));
    },

    nothingSelected: Ember.computed.empty('selectedValue'),
    promptIsDisabled: Ember.computed.not('promptIsSelectable'),
    hasGrouping: Ember.computed.or('optionsArePreGrouped', 'groupLabelPath'),
    computedOptionValuePath: Ember.computed.or('optionValuePath', 'optionTargetPath'),
    optionGroups: Ember.computed('options.[]', function () {
      let groupLabelPath = Ember.get(this, 'groupLabelPath');
      let options = Ember.get(this, 'options');
      let groups = Ember.A();

      if (!groupLabelPath) {
        return options;
      }

      options.forEach(item => {
        let label = Ember.get(item, groupLabelPath);

        if (label) {
          let group = groups.findBy('groupName', label);

          if (group == null) {
            group = Ember.Object.create({
              groupName: label,
              options: Ember.A()
            });
            groups.pushObject(group);
          }

          Ember.get(group, 'options').pushObject(item);
        } else {
          groups.pushObject(item);
        }
      });
      return groups;
    }),

    change() {
      let value;

      if (Ember.get(this, 'multiple') === true) {
        value = this._selectedMultiple();
      } else {
        value = this._selectedSingle();
      }

      this.update(value);
    },

    prompt: Ember.computed.alias('includeBlank'),

    _selectedMultiple() {
      let options = this.element.options;
      let selectedValues = [];

      for (let i = 0; i < options.length; i++) {
        if (options[i].selected) {
          selectedValues.push(options[i].value);
        }
      }

      return selectedValues.map(selectedValue => {
        return this._findOption(selectedValue);
      });
    },

    _selectedSingle() {
      let selectedValue = this.element.value;
      return this._findOption(selectedValue);
    },

    _findOption(value) {
      let options = Ember.get(this, 'options');
      let optionValuePath = Ember.get(this, 'computedOptionValuePath');
      let optionTargetPath = Ember.get(this, 'optionTargetPath');
      let optionsArePreGrouped = Ember.get(this, 'optionsArePreGrouped');

      let findOption = item => {
        if (optionValuePath) {
          return `${Ember.get(item, optionValuePath)}` === value;
        } else {
          return `${item}` === value;
        }
      };

      let foundOption;

      if (optionsArePreGrouped) {
        foundOption = options.reduce((found, group) => {
          return found || Ember.get(group, 'options').find(findOption);
        }, undefined);
      } else {
        foundOption = options.find(findOption);
      }

      if (optionTargetPath && !Ember.isNone(foundOption)) {
        return Ember.get(foundOption, optionTargetPath);
      } else {
        return foundOption;
      }
    }

  });
  OneWaySelectComponent.reopenClass({
    positionalParams: ['paramValue']
  });
  var _default = OneWaySelectComponent;
  _exports.default = _default;
});