define("ember-event-helpers/helpers/prevent-default", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.preventDefault = preventDefault;
  _exports.default = void 0;

  function preventDefault([handler]) {
    (false && !(!handler || typeof handler === 'function') && Ember.assert(`Expected '${handler}' to be a function, if present.`, !handler || typeof handler === 'function'));
    return function (event) {
      (false && !(event && typeof event.preventDefault === 'function') && Ember.assert(`Expected '${event}' to be an Event and have a 'preventDefault' method.`, event && typeof event.preventDefault === 'function'));
      event.preventDefault();
      if (handler) handler(event);
    };
  }

  var _default = Ember.Helper.helper(preventDefault);

  _exports.default = _default;
});