define("ember-svg-jar/inlined/na-fiscal-year", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g data-name=\"Layer 2\"><g data-name=\"Layer 1\"><path fill=\"#f2f5f9\" d=\"M25.39 90.38L14.1 83.86 55.95 59.5l11.29 6.52-41.85 24.36z\"/><path d=\"M17.22 74.17l45.92-26.51a1.17 1.17 0 00.56-.78l.42-2.09-46.9 27.57z\" fill=\"#e9edf4\"/><path d=\"M60.36 44L19.88 63.67l-2 9.63a1.21 1.21 0 01-2.33.14L.12 27.7a2.33 2.33 0 011-2.76L44.09.16a1.16 1.16 0 011.68.63z\" fill=\"#dce2ea\"/><path d=\"M43.4 13.36l-31.15 18-2-1.61a2.33 2.33 0 00-2.61-.21l-5.77 3.32 13.7 40.58a1.21 1.21 0 002.33-.14L60.36 44 45 13.84a1.16 1.16 0 00-1.6-.48z\" fill=\"#cfd7e2\"/><path d=\"M68.26 15.16l-28.93 16.7.67-3.38a1.16 1.16 0 00-1.71-1.24l-10.56 6.11a4.43 4.43 0 00-2.13 2.94l-.81 4-.1.06-6.79 33 45.82-26.58L70 16.4a1.15 1.15 0 00-1.74-1.24z\" fill=\"#e9edf4\"/></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 70 90.38"
    }
  };
  _exports.default = _default;
});