define("ember-svg-jar/inlined/chevron-double-down-white", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"#FFF\" fill-rule=\"evenodd\"><path d=\"M.924.93L3.752 3.76l-.88.88L.044 1.81z\"/><path d=\"M1.996 3.762L4.824.933l.877.877L2.872 4.64zM.924 4.03L3.752 6.86l-.88.88L.044 4.91z\"/><path d=\"M1.996 6.862l2.828-2.829.877.877L2.872 7.74z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "6",
      "height": "8",
      "viewBox": "0 0 6 8"
    }
  };
  _exports.default = _default;
});