define("ember-svg-jar/inlined/sort-hide-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"#231f20\"><path d=\"M23.325.5H1.675C1.027.5.5 1.026.5 1.674v21.65c0 .648.527 1.175 1.175 1.175h10.82l.005.001.005-.001h10.82c.647 0 1.175-.527 1.175-1.175V1.674C24.5 1.026 23.973.5 23.325.5zM1.5 23.325V1.674c0-.096.078-.174.175-.174H12v22H1.675a.175.175 0 01-.175-.175zm22 0a.175.175 0 01-.175.175H13v-22h10.325c.097 0 .175.079.175.175v21.65z\"/><path d=\"M4.363 7.035a.5.5 0 00.384-.179l1.229-1.47v5.404a.5.5 0 001 0V5.385l1.229 1.469a.502.502 0 00.769-.642L6.86 3.687c-.005-.006-.012-.008-.017-.014-.011-.011-.021-.022-.033-.032-.005-.005-.007-.012-.013-.017-.015-.012-.033-.016-.049-.026-.021-.014-.039-.03-.062-.04-.013-.006-.027-.01-.04-.015a.312.312 0 00-.073-.018c-.026-.005-.05-.012-.077-.013-.007 0-.013-.004-.02-.004-.008 0-.015.004-.022.005-.023 0-.044.006-.066.01-.028.005-.055.009-.083.019a.476.476 0 00-.04.016c-.021.01-.039.025-.058.038-.017.011-.036.015-.052.029-.006.004-.008.011-.013.017a.314.314 0 00-.032.031c-.005.006-.012.008-.017.014L3.979 6.214a.502.502 0 00.384.821zM8.206 18.143l-1.23 1.471v-5.405a.5.5 0 00-1 0v5.405l-1.229-1.469a.5.5 0 10-.767.642l2.112 2.525.017.013a.484.484 0 00.116.089c.017.01.03.024.048.032a.49.49 0 00.406 0c.018-.008.031-.022.048-.032a.484.484 0 00.116-.089c.005-.005.012-.007.017-.013l2.114-2.528a.5.5 0 00-.768-.641zM18.29 9.964c-2.108 0-4.185 1.984-4.185 2.535s2.077 2.535 4.185 2.535 4.185-1.984 4.185-2.535-2.078-2.535-4.185-2.535zm0 3.713a1.177 1.177 0 110-2.354 1.177 1.177 0 010 2.354z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 25 25"
    }
  };
  _exports.default = _default;
});