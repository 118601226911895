define("ember-svg-jar/inlined/error-x", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>Fill 434 + Fill 435</title><g stroke=\"#FFF\" fill=\"#FFF\" fill-rule=\"evenodd\"><path d=\"M28.755 14.244a.834.834 0 00-1.178 0L21.5 20.32l-6.078-6.076a.834.834 0 00-1.178 1.178L20.32 21.5l-6.076 6.078a.834.834 0 001.178 1.178L21.5 22.68l6.077 6.076a.834.834 0 001.178-1.178L22.68 21.5l6.076-6.077a.834.834 0 000-1.178\"/><path d=\"M21 1C9.955 1 1 9.953 1 21c0 11.045 8.955 20 20 20s19.998-8.955 19.998-20C40.998 9.953 32.045 1 21 1h0zm12.963 32.963A18.273 18.273 0 0121 39.333a18.273 18.273 0 01-12.963-5.37A18.273 18.273 0 012.667 21c0-5.065 2.051-9.643 5.37-12.963A18.266 18.266 0 0121 2.667c5.065 0 9.643 2.05 12.963 5.37A18.273 18.273 0 0139.333 21c0 5.065-2.051 9.643-5.37 12.963h0z\"/></g>",
    "attrs": {
      "width": "42",
      "height": "42",
      "viewBox": "0 0 42 42",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});