define("ember-inputmask/components/one-way-currency-mask", ["exports", "ember-inputmask/components/one-way-input-mask"], function (_exports, _oneWayInputMask) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class OneWayCurrencyMask
   */
  var _default = _oneWayInputMask.default.extend({
    /**
     *
     * @field mask
     * @override
     */
    mask: 'currency'
  });

  _exports.default = _default;
});