define("ember-freestyle/templates/components/freestyle-snippet", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MhsbQriv",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,3],[[35,2]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"FreestyleSnippet-title\"],[12],[2,\"\\n    \"],[1,[34,2]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,3],[[35,1]],null,[[\"default\"],[{\"statements\":[[10,\"pre\"],[15,0,[34,0]],[12],[1,[34,1]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"language\",\"source\",\"title\",\"if\"]}",
    "moduleName": "ember-freestyle/templates/components/freestyle-snippet.hbs"
  });

  _exports.default = _default;
});