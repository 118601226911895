define("ember-svg-jar/inlined/sort-desc-numbers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g class=\"fill-current\" fill=\"#90A4AE\"><path d=\"M8 5h7v1H8zM11 7h4v1h-4zM5 3h10v1H5zM2 1h13v1H2zM3.489 13.489H2.457c.2.96.759 1.608 1.833 1.608 1.471-.066 2.037-1.282 2.037-3.122v-.216c0-1.716-.767-2.654-2.009-2.654-1.167 0-1.923.847-1.923 1.946 0 1.043.551 1.91 1.642 1.91.494 0 .923-.191 1.222-.504-.054 1.18-.45 1.733-.958 1.742-.514 0-.685-.298-.812-.71zm-.04-2.452c0-.617.364-1.054.872-1.054.537 0 .923.372.923 1.165v.028c0 .492-.34.91-.909.91-.582 0-.886-.441-.886-1.049zm6.48.633H7.145v.824h2.784v-.823zm4.795-.09c0-1.654-.858-2.478-2.023-2.478s-2.026.824-2.026 2.478v.948c0 1.716.858 2.552 2.029 2.552 1.165 0 2.02-.836 2.02-2.552v-.948zm-1.083 1.105c0 1.008-.403 1.474-.937 1.474-.54 0-.949-.466-.949-1.474v-1.259c0-.954.406-1.412.946-1.412.54 0 .94.458.94 1.412v1.259z\"/></g>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});