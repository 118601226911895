define("ember-svg-jar/inlined/cancel-small-x", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path class=\"fill-current\" d=\"M19.4 5.9c-.3-.3-.8-.3-1.1 0l-5.8 5.8-5.8-5.8c-.3-.3-.8-.3-1.1 0-.3.3-.3.8 0 1.1l5.8 5.8-5.8 5.8c-.3.3-.3.8 0 1.1.1.2.3.3.5.3s.4-.1.6-.2l5.8-5.8 5.8 5.8c.2.2.4.2.6.2.2 0 .4-.1.6-.2.3-.3.3-.8 0-1.1l-5.8-5.8L19.4 7c.3-.3.3-.8 0-1.1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 25 25",
      "fill": "#231F20"
    }
  };
  _exports.default = _default;
});