define("ember-svg-jar/inlined/accept", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M24.346 4.235a.5.5 0 00-.707.016L8.837 19.715l-7.511-6.453a.501.501 0 00-.652.758l8.229 7.072 15.458-16.15a.5.5 0 00-.015-.707z\" fill=\"#010101\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 25 25"
    }
  };
  _exports.default = _default;
});