define("ember-svg-jar/inlined/budget-completed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\" transform=\"translate(0 .121)\"><circle cx=\"14.5\" cy=\"14.5\" r=\"14.5\" class=\"fill-current\" fill=\"#4DB6AC\"/><path fill=\"#FFF\" d=\"M12.552 17.254l-2.096-2.096a4.286 4.286 0 00-6.06 0l-.046.046 5.126 5.126 3.076 3.076 12.304-12.303-.046-.046a4.286 4.286 0 00-6.06 0l-6.198 6.197z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "29",
      "height": "30",
      "viewBox": "0 0 29 30"
    }
  };
  _exports.default = _default;
});