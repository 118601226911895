define("ember-svg-jar/inlined/na-allocations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g data-name=\"Layer 2\"><g data-name=\"Layer 1\"><path d=\"M24 100.93L8 91.71a1.21 1.21 0 010-2.09L43.88 68.9a1.19 1.19 0 011.21 0L62 78.63a1.21 1.21 0 010 2.09l-35 20.21a3 3 0 01-3 0z\" fill=\"#f2f5f9\"/><path fill=\"#dce2ea\" d=\"M22.87 86.43h-2.4v2.16l2.51-.03-.11-2.13z\"/><path fill=\"#cfd7e2\" d=\"M21.42 53.08L0 40.72l42.57-24.58 21.41 12.37-42.56 24.57z\"/><path d=\"M19.87 88.43L1.23 77.92A2.41 2.41 0 010 75.82V40.73L21.67 53v34.38a1.2 1.2 0 01-1.8 1.05zM64 63.17l-21.4-11V16.14l21.38 12.37.02 34.66z\" fill=\"#dce2ea\"/><path d=\"M21.67 35.82A5.75 5.75 0 0116 31.14a6.38 6.38 0 00-.1 1.08 5.75 5.75 0 1011.5 0 5.49 5.49 0 00-.1-1.08 5.75 5.75 0 01-5.63 4.68z\" fill=\"#e9edf4\"/><ellipse cx=\"21.67\" cy=\"31.14\" rx=\"5.65\" ry=\"4.67\" fill=\"#f2f5f9\"/><path d=\"M35.34 27.73a5.75 5.75 0 01-5.65-4.68 5.49 5.49 0 00-.1 1.08 5.75 5.75 0 1011.5 0 6.38 6.38 0 00-.1-1.08 5.75 5.75 0 01-5.65 4.68z\" fill=\"#dce2ea\"/><ellipse cx=\"35.34\" cy=\"23.05\" rx=\"5.65\" ry=\"4.67\" fill=\"#e9edf4\"/><path d=\"M21.67 13.85a8.52 8.52 0 01-8.37-6.93 8.52 8.52 0 1016.7 0 8.52 8.52 0 01-8.33 6.93z\" fill=\"#dce2ea\"/><ellipse cx=\"21.67\" cy=\"6.92\" rx=\"8.37\" ry=\"6.92\" fill=\"#e9edf4\"/><path d=\"M43 41.57a5.75 5.75 0 01-5.65-4.67 5.49 5.49 0 00-.15 1.1 5.76 5.76 0 0011.51 0 6.38 6.38 0 00-.1-1.08A5.75 5.75 0 0143 41.57z\" fill=\"#e9edf4\"/><ellipse cx=\"42.96\" cy=\"36.9\" rx=\"5.65\" ry=\"4.67\" fill=\"#f2f5f9\"/><path d=\"M23.48 88.4l39.31-22.69a2.43 2.43 0 001.21-2.1v-35.1L21.67 53v34.36a1.2 1.2 0 001.81 1.04z\" fill=\"#e9edf4\"/></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 64 101.33"
    }
  };
  _exports.default = _default;
});