define("ember-svg-jar/inlined/chevron-circle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M16.904 8.805a8 8 0 11-16 0 8 8 0 0116 0z\" fill=\"#78909C\" class=\"fill-current\"/><path fill=\"#fff\" d=\"M5.37 6.34l4.949 4.95-1.414 1.414-4.95-4.95z\"/><path fill=\"#fff\" d=\"M13.855 7.754l-4.95 4.95-1.414-1.414 4.95-4.95z\"/>",
    "attrs": {
      "width": "17",
      "height": "17",
      "viewBox": "0 0 17 17",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});