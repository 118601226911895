define("ember-file-upload/components/base-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    fileQueue: Ember.inject.service(),

    didReceiveAttrs() {
      this._super(...arguments);

      if (this.queue) {
        let {
          accept,
          multiple,
          disabled,
          onFileAdd
        } = this;
        Ember.setProperties(this.queue, {
          accept,
          multiple,
          disabled,
          onFileAdd
        });
      }
    },

    queue: Ember.computed('name', {
      get() {
        let queueName = this.name;

        if (queueName != null) {
          let queues = this.fileQueue;
          return queues.find(queueName) || queues.create(queueName);
        } else {
          return undefined;
        }
      }

    })
  });

  _exports.default = _default;
});