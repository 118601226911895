define("ember-svg-jar/inlined/chat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path class=\"stroke-current fill-current\" fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M15.5 7.5a4 4 0 011.286 7.789v2.497L13.929 15.5H10.5a4 4 0 010-8h5z\" fill=\"#78909C\" stroke=\"#78909C\" stroke-linejoin=\"round\"/><path class=\"stroke-current fill-current\" d=\"M16.786 16.745a.5.5 0 01-.812.391l.812.65v-1.04zM13.753 15.5a.5.5 0 01.313.11l-.137-.11h-.176zm3.033.132a.5.5 0 01.298-.458c-.098.042-.197.08-.298.115v.343z\" fill=\"#78909C\" stroke=\"#78909C\" stroke-linejoin=\"round\"/><path class=\"stroke-current\" fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M4.5 3.5a4 4 0 00-.5 7.97v2.459L6.819 11.5H9.5a4 4 0 000-8h-5z\" fill=\"#fff\" stroke=\"#78909C\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});