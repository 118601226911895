define("ember-svg-jar/inlined/cancel-x", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path class=\"fill-current stroke-medium stroke-current\" fill=\"#231F20\" d=\"M13.207 12.5l9.646-9.646a.5.5 0 00-.707-.707L12.5 11.793 2.854 2.146a.5.5 0 00-.707.707l9.646 9.646-9.646 9.646a.5.5 0 00.707.709l9.646-9.646 9.646 9.646a.502.502 0 00.708 0 .5.5 0 000-.707L13.207 12.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 25 25"
    }
  };
  _exports.default = _default;
});