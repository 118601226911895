define("ember-svg-jar/inlined/change-notice", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M16 8A8 8 0 110 8a8 8 0 0116 0z\" fill=\"#fff\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M8 11.636a3.636 3.636 0 100-7.272 3.636 3.636 0 000 7.272zM8 16A8 8 0 108 0a8 8 0 000 16z\" fill=\"#BEC7D3\"/>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});