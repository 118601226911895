define("ember-svg-jar/inlined/notification-x", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#FFF\" fill-rule=\"evenodd\" d=\"M9.555 7.9l4.138 4.138a1.17 1.17 0 01-1.655 1.655L7.899 9.555l-4.138 4.138a1.17 1.17 0 01-1.655-1.655l4.138-4.139-4.138-4.138a1.17 1.17 0 011.655-1.655L7.9 6.244l4.139-4.138a1.17 1.17 0 011.655 1.655L9.555 7.9z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16"
    }
  };
  _exports.default = _default;
});