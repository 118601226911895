define("ember-svg-jar/inlined/checkmark", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path class=\"fill-current\" fill=\"#4DB6AC\" fill-rule=\"evenodd\" d=\"M6.429 9.5l-2.32-2.32a2.4 2.4 0 00-3.394 0L0 7.893l4.017 4.018 2.41 2.41L16.07 4.68l-.713-.714a2.4 2.4 0 00-3.394 0L6.429 9.5z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16"
    }
  };
  _exports.default = _default;
});