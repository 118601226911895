define("ember-svg-jar/inlined/log-out", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M14.097 13.073a.5.5 0 00-.5.5v4.33H8.065V4.998c0-.431-.374-.58-.731-.784L2.18 1.03h11.417v5.375a.5.5 0 001 0V.53a.5.5 0 00-.5-.5H.554L.54.032C.173-.118 0 .28 0 .818v17.139c0 .43.298.654.692.904l6.642 4.057c.365.227.731-.091.731-.522v-3.492h6.032a.5.5 0 00.5-.5v-4.831a.5.5 0 00-.5-.5\" fill=\"#90A4AE\" class=\"fill-current\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M20.615 10.205l-4.094-3.103a.5.5 0 00-.802.386l-.042 1.648-5.173-.04c-.15-.016-.262.051-.356.145a.496.496 0 00-.148.355v1.683a.5.5 0 00.5.5h5.164v1.576a.498.498 0 00.777.416l4.148-2.751a.499.499 0 00.026-.815\" fill=\"#90A4AE\" class=\"fill-current\"/>",
    "attrs": {
      "width": "21",
      "height": "23",
      "viewBox": "0 0 21 23",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});