define("ember-svg-jar/inlined/chevron-up-arrow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"#FFF\" fill-rule=\"evenodd\"><path d=\"M8.414 6.828L4.172 2.586l1.414-1.414 4.242 4.242z\"/><path d=\"M.171 5.415L4.997.589 6.41 2.003 1.585 6.83z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "10",
      "height": "7",
      "viewBox": "0 0 10 7"
    }
  };
  _exports.default = _default;
});