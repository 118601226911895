define("ember-svg-jar/inlined/delete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g class=\"fill-current\" fill=\"#9EAAB2\" fill-rule=\"evenodd\"><path d=\"M.75 3h10.5l-.686 10.068a1 1 0 01-.998.932H2.434a1 1 0 01-.998-.932L.75 3zM5.5 5v7h1V5h-1zM8 5v7h1V5H8zM3 5v7h1V5H3z\"/><path fill-rule=\"nonzero\" d=\"M4 1V0h4v1h4v1.5H0V1h4z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "12",
      "height": "14",
      "viewBox": "0 0 12 14"
    }
  };
  _exports.default = _default;
});