define("ember-freestyle/components/freestyle-dynamic-input", ["exports", "ember-freestyle/templates/components/freestyle-dynamic-input"], function (_exports, _freestyleDynamicInput) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _freestyleDynamicInput.default,
    classNames: ['FreestyleDynamic-input'],
    isCheckbox: Ember.computed.equal('inputType', 'checkbox'),
    isTextarea: Ember.computed.equal('inputType', 'textarea'),
    isSelect: Ember.computed.equal('inputType', 'select'),
    isNumber: Ember.computed.equal('inputType', 'number'),
    isRange: Ember.computed.equal('inputType', 'range'),
    inputId: Ember.computed('propertyName', function () {
      return `${this.get('elementId')}_${this.get('propertyName')}`;
    }),
    actions: {
      toggleCheckbox() {
        let newValue = !this.get('value');
        Ember.get(this, 'changeValueTo')(newValue);
        return false;
      },

      sendChangedValue(newValue) {
        Ember.get(this, 'changeValueTo')(newValue);
      },

      sendChangedNumberValue(newValue) {
        let coercedValue = newValue === '' ? null : Number(newValue);
        Ember.get(this, 'changeValueTo')(coercedValue);
      }

    }
  });

  _exports.default = _default;
});