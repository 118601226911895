define("ember-svg-jar/inlined/circle-plus-outline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M8 14.5a6.5 6.5 0 100-13 6.5 6.5 0 000 13zM8 16A8 8 0 118 0a8 8 0 010 16z\" fill=\"#40555B\"/><path d=\"M11.21 7.25h-6.5a.733.733 0 00-.75.75c0 .414.308.75.75.75h6.5c.442 0 .75-.336.75-.75a.733.733 0 00-.75-.75z\" fill=\"#40555B\"/><path d=\"M8.75 11.29v-6.5A.733.733 0 008 4.04a.733.733 0 00-.75.75v6.5c0 .442.336.75.75.75s.75-.308.75-.75z\" fill=\"#40555B\"/>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});