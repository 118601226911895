define("ember-svg-jar/inlined/edit-circle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<circle cx=\"8\" cy=\"8\" r=\"8\" transform=\"rotate(-180 8 8)\" fill=\"#9EAAB2\" class=\"fill-current\"/><path fill=\"#fff\" stroke=\"#fff\" d=\"M6.868 10.687L5.313 9.13 8.754 5.69l1.556 1.555zM11.394 6.162L9.838 4.606l.401-.4a1.1 1.1 0 111.556 1.555l-.4.4zM5.542 11.528l-1.513.443.443-1.512 1.07 1.07z\"/>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});