define("ember-svg-jar/inlined/download-cancel-x", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"#9EAAB2\" class=\"fill-current\" fill-rule=\"evenodd\"><rect width=\"18.667\" height=\"2.667\" x=\"-1.467\" y=\"6.533\" rx=\"1\" transform=\"rotate(135 7.866 7.866)\"/><rect width=\"18.667\" height=\"2.667\" x=\"-1.467\" y=\"6.533\" rx=\"1\" transform=\"rotate(-135 7.866 7.866)\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "15",
      "height": "15",
      "viewBox": "0 0 15 15"
    }
  };
  _exports.default = _default;
});