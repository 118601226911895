define("ember-svg-jar/inlined/upload", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M9.132 10.51V4.89l2.475 2.474A1 1 0 0013.02 5.95L8.778 1.707a1 1 0 00-1.414 0L3.121 5.95a1 1 0 101.415 1.414l2.596-2.596v5.742a1 1 0 102 0z\" fill=\"#9EAAB2\" class=\"group-hover:fill-current fill-current\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M0 11a1 1 0 112 0v3h12v-3a1 1 0 112 0v4a1 1 0 01-1 1H1a1 1 0 01-1-1v-4z\" fill=\"#9EAAB2\" class=\"group-hover:fill-current fill-current\"/>",
    "attrs": {
      "width": "16",
      "height": "16",
      "fill": "none",
      "viewBox": "0 0 16 16"
    }
  };
  _exports.default = _default;
});