define("ember-svg-jar/inlined/bookmarks-empty", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><path class=\"stroke-current\" stroke=\"#9EAAB2\" d=\"M10.5 3.5v-3H.5V15l3-2.2V3.5z\"/><path class=\"stroke-current\" stroke=\"#9EAAB2\" d=\"M3.5 3.5V18l4.4-3.2c.4-.3.8-.3 1.2 0l4.4 3.2V3.5h-10z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "14",
      "height": "19",
      "viewBox": "0 0 14 19"
    }
  };
  _exports.default = _default;
});