define("ember-svg-jar/inlined/bookmark-background", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M93.602 6.861L17.71 6.798h-.001c-.507 0-.981.274-1.27.732L8.973 19.388a1.796 1.796 0 00-.255.741c-.015.068-.041.131-.041.203v85.561c0 .55.45 1 1 1h75.938c.55 0 1-.45 1-1V20.332c0-.55-.45-1-1-1H12.767l5.751-9.134 73.538.061-.041 86.718h1.586c.853 0 1.545-.76 1.545-1.7V8.561c-.001-.938-.692-1.7-1.544-1.7z\" fill=\"#d2d3d4\"/><path fill=\"#e5e5e5\" d=\"M15.844 108.473H33.92v23.049l-9.047-5.825-9.046 5.825zM88.124 16.053l.115-1.405.998-.807.021-1.429 1.22-.994H19.524l-4.208 6.562h70.852v.004c.199-.004.381.02.553.058l.254-1.44 1.149-.549z\"/><path d=\"M87.941 102.79l.033-.188 1.15-.55.115-1.405.998-.807.021-1.429.536-.437V11.806l-1.04.846-.021 1.431-1.014.818-.121 1.479-1.178.562-.223 1.268c.468.256.742.714.743 1.293v83.287h.001z\" fill=\"#e5e5e5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 105 136"
    }
  };
  _exports.default = _default;
});