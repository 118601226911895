define("ember-svg-jar/inlined/allovue_logo_bw", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M-3396.8 3492.8h-6.2c-8.9 0-16.1-7.3-16.1-16.1v-.1l22.3-10.4v26.6zM-3372.6 3492.8h-18.6v-29.2l18.6-8.7zM-3347.2 3443.1v33.6c0 8.9-7.3 16.1-16.1 16.1h-3.7v-40.5l19.8-9.2zM-3403 3420.8h39.8c8.9 0 16.1 7.3 16.1 16.1v.9l-72 33.5v-34.4c-.1-8.8 7.2-16.1 16.1-16.1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "-3419 3421 71.7 72"
    }
  };
  _exports.default = _default;
});