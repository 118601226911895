define("ember-svg-jar/inlined/file-csv", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\" transform=\"translate(9)\"><path stroke=\"#9EAAB2\" d=\"M24.5 6.047L18.689.5H4a.5.5 0 00-.5.5v26a.5.5 0 00.5.5h20a.5.5 0 00.5-.5V6.047z\"/><path stroke=\"#9EAAB2\" stroke-linecap=\"round\" stroke-linejoin=\"round\" d=\"M23 7h-5V2\"/><rect width=\"23\" height=\"10\" y=\"12\" fill=\"#9EAAB2\" rx=\"1\"/><path fill=\"#FFF\" d=\"M9.08 15.895l-1.086.12c-.153-.603-.593-.913-1.153-.913-.801 0-1.341.654-1.341 1.997 0 1.344.54 1.98 1.34 1.98.56 0 .995-.303 1.154-.889l1.08.117C8.798 19.44 7.929 20.08 6.84 20.08c-1.435 0-2.443-1.108-2.443-2.98 0-1.878 1.008-2.998 2.443-2.998 1.088 0 1.966.645 2.239 1.793zm3.926-.065c-.051-.5-.404-.793-1.02-.793-.602 0-1.017.284-1.017.696 0 .4.426.597 1.102.815l.31.1c1.096.355 1.704.83 1.704 1.718 0 1.023-.798 1.722-2.133 1.722-1.17 0-2.2-.61-2.222-1.852h1.08c.042.605.508.92 1.167.92.716 0 1.043-.372 1.043-.77 0-.412-.333-.65-1.19-.914l-.324-.094c-.946-.281-1.611-.807-1.611-1.642 0-.977.898-1.634 2.1-1.634 1.207 0 2.042.688 2.062 1.728h-1.051zm2.872-1.648l1.312 4.37h.074l1.31-4.37h1.199L17.81 20h-1.17l-1.964-5.818h1.202z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "36",
      "height": "28",
      "viewBox": "0 0 36 28"
    }
  };
  _exports.default = _default;
});