define("ember-svg-jar/inlined/change-budget-lea", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"#231f20\"><path d=\"M9 19.628H3.718a.316.316 0 01-.315-.315V5.136h16.122v10.552a.5.5 0 001 0V2.19c0-.725-.59-1.315-1.315-1.315H3.718c-.726 0-1.315.59-1.315 1.315v17.122c0 .725.59 1.315 1.315 1.315H9a.5.5 0 000-.999zM3.718 1.875h1.131v1.131a.5.5 0 001 0V1.875h1.445v1.131a.5.5 0 001 0V1.875H9.74v1.131a.5.5 0 001 0V1.875h1.446v1.131a.5.5 0 001 0V1.875h1.445v1.131a.5.5 0 001 0V1.875h1.446v1.131a.5.5 0 001 0V1.875h1.131c.174 0 .315.142.315.315v1.946H3.402V2.19c0-.173.142-.315.316-.315z\"/><path d=\"M17.578 10.843a.5.5 0 000-1h-2.762v-2.76a.5.5 0 00-1 0v2.761H9.109V7.083a.5.5 0 00-1 0v2.761h-2.76a.5.5 0 000 1H8.11v3.08H5.349a.5.5 0 000 1H8.11v2.759a.5.5 0 001 0v-2.759h4.707v2.202a.5.5 0 001 0v-2.202h2.762a.5.5 0 000-1h-2.762v-3.08h2.761v-.001zm-3.762 3.08H9.109v-3.08h4.707v3.08z\"/><path d=\"M23.765 20.034l-5.37-4.07a.407.407 0 00-.654.314l-.061 2.405-7.029-.055c-.125.017-.213.042-.29.118s-.121.181-.121.289v2.208c0 .225.182.408.408.408h7.022v2.315a.406.406 0 00.633.34l5.441-3.609a.408.408 0 00.021-.663z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 25 25"
    }
  };
  _exports.default = _default;
});