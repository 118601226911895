define("ember-svg-jar/inlined/printer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#9EAAB2\" d=\"M0 2h16v9H0z\"/><path fill=\"#9EAAB2\" d=\"M0 2h16v9H0z\"/><path fill=\"#9EAAB2\" stroke=\"#fff\" d=\"M2.5.5h11v5h-11z\"/><path fill=\"#fff\" stroke=\"#9EAAB2\" d=\"M2.5 8.5h11v7h-11z\"/><path fill=\"#fff\" d=\"M11 7h1v1h-1zM13 7h1v1h-1z\"/>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});