define("ember-basic-dropdown/templates/components/basic-dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ANs54K+X",
    "block": "{\"symbols\":[\"api\",\"&default\",\"&attrs\",\"@rootEventType\",\"@preventScroll\",\"@contentComponent\",\"@triggerComponent\"],\"statements\":[[6,[37,5],[[30,[36,4],null,[[\"uniqueId\",\"isOpen\",\"disabled\",\"actions\",\"Trigger\",\"Content\"],[[32,0,[\"publicAPI\",\"uniqueId\"]],[32,0,[\"publicAPI\",\"isOpen\"]],[32,0,[\"publicAPI\",\"disabled\"]],[32,0,[\"publicAPI\",\"actions\"]],[30,[36,3],[[30,[36,2],[[32,7],\"basic-dropdown-trigger\"],null]],[[\"dropdown\",\"hPosition\",\"renderInPlace\",\"vPosition\"],[[30,[36,1],[[32,0,[\"publicAPI\"]]],null],[30,[36,1],[[32,0,[\"hPosition\"]]],null],[30,[36,1],[[32,0,[\"renderInPlace\"]]],null],[30,[36,1],[[32,0,[\"vPosition\"]]],null]]]],[30,[36,3],[[30,[36,2],[[32,6],\"basic-dropdown-content\"],null]],[[\"dropdown\",\"hPosition\",\"renderInPlace\",\"preventScroll\",\"rootEventType\",\"vPosition\",\"destination\",\"top\",\"left\",\"right\",\"width\",\"height\",\"otherStyles\"],[[30,[36,1],[[32,0,[\"publicAPI\"]]],null],[30,[36,1],[[32,0,[\"hPosition\"]]],null],[30,[36,1],[[32,0,[\"renderInPlace\"]]],null],[30,[36,1],[[32,5]],null],[30,[36,2],[[32,4],\"click\"],null],[30,[36,1],[[32,0,[\"vPosition\"]]],null],[30,[36,1],[[32,0,[\"destination\"]]],null],[30,[36,1],[[32,0,[\"top\"]]],null],[30,[36,1],[[32,0,[\"left\"]]],null],[30,[36,1],[[32,0,[\"right\"]]],null],[30,[36,1],[[32,0,[\"width\"]]],null],[30,[36,1],[[32,0,[\"height\"]]],null],[30,[36,1],[[32,0,[\"otherStyles\"]]],null]]]]]]]],null,[[\"default\"],[{\"statements\":[[6,[37,0],[[32,0,[\"renderInPlace\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[11,\"div\"],[24,0,\"ember-basic-dropdown\"],[17,3],[12],[18,2,[[32,1]]],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[18,2,[[32,1]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"readonly\",\"or\",\"component\",\"hash\",\"with\"]}",
    "moduleName": "ember-basic-dropdown/templates/components/basic-dropdown.hbs"
  });

  _exports.default = _default;
});