define("ember-svg-jar/inlined/plus", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path class=\"fill-current\" d=\"M15.32 7.14h-5.27v-5.5a1.68 1.68 0 00-3.35 0v5.5h-5a1.64 1.64 0 100 3.29h5v4.93a1.68 1.68 0 003.35 0v-4.94h5.27A1.64 1.64 0 0017 8.78a1.69 1.69 0 00-1.68-1.64z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 17 17",
      "fill": "#010101"
    }
  };
  _exports.default = _default;
});