define("ember-light-table/templates/components/lt-foot", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PGTNy0Xf",
    "block": "{\"symbols\":[\"column\",\"&default\"],\"statements\":[[6,[37,16],null,[[\"to\",\"renderInPlace\"],[[30,[36,6],[[35,15],\"_inline_foot\"],null],[35,14]]],[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"table\"],[15,0,[34,11]],[12],[2,\"\\n    \"],[10,\"tfoot\"],[14,0,\"lt-foot\"],[12],[2,\"\\n\"],[2,\"      \"],[1,[30,[36,12],null,[[\"columns\"],[[35,8]]]]],[2,\"\\n\"],[6,[37,13],[[27,[32,2]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[18,2,[[35,8]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[10,\"tr\"],[12],[2,\"\\n\"],[6,[37,10],[[30,[36,9],[[30,[36,9],[[35,8]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"            \"],[1,[30,[36,7],[[30,[36,6],[\"light-table/columns/\",[32,1,[\"type\"]]],null],[32,1]],[[\"table\",\"tableActions\",\"extra\",\"sortIcons\",\"resizeOnDrag\",\"click\",\"doubleClick\",\"onColumnResized\",\"onColumnDrag\",\"onColumnDrop\"],[[35,5],[35,4],[35,3],[35,2],[35,1],[30,[36,0],[[32,0],\"onColumnClick\",[32,1]],null],[30,[36,0],[[32,0],\"onColumnDoubleClick\",[32,1]],null],[30,[36,0],[[32,0],\"onColumnResized\"],null],[30,[36,0],[[32,0],\"onColumnDrag\"],null],[30,[36,0],[[32,0],\"onColumnDrop\"],null]]]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"resizeOnDrag\",\"sortIcons\",\"extra\",\"tableActions\",\"table\",\"concat\",\"component\",\"columns\",\"-track-array\",\"each\",\"tableClassNames\",\"lt-scaffolding-row\",\"if\",\"renderInPlace\",\"tableId\",\"ember-wormhole\"]}",
    "moduleName": "ember-light-table/templates/components/lt-foot.hbs"
  });

  _exports.default = _default;
});