define("ember-svg-jar/inlined/line-chart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>line-chart</title><g fill=\"none\" fill-rule=\"evenodd\"><path d=\"M22.96 20.76H1.877V.669a.5.5 0 00-1 0V21.76H22.96a.5.5 0 000-1\" fill=\"#030404\"/><path d=\"M5 16.873l3.628-6.314c.137-.24.42-.291.64-.11l3.465 2.853c.216.178.492.128.617-.119l2.55-4.991c.124-.244.381-.281.576-.08l2.36 2.432c.194.2.466.166.599-.063L22.625 5\" stroke=\"#000\" stroke-width=\"2\" stroke-linecap=\"square\"/></g>",
    "attrs": {
      "width": "24",
      "height": "22",
      "viewBox": "0 0 24 22",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});