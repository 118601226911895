define("ember-freestyle/templates/components/freestyle-notes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Yx24SHNw",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"FreestyleNotes\"],[12],[2,\"\\n  \"],[1,[30,[36,1],null,[[\"text\",\"class\"],[[35,0],\"FreestyleNotes-content\"]]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"text\",\"freestyle-showdown-content\"]}",
    "moduleName": "ember-freestyle/templates/components/freestyle-notes.hbs"
  });

  _exports.default = _default;
});