define("ember-svg-jar/inlined/bookmarks-added", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><path class=\"fill-none stroke-current\" stroke=\"#9EAAB2\" d=\"M.5.5v14.518l4.412-3.208a1 1 0 011.176 0l4.412 3.208V.5H.5z\"/><path class=\"fill-current\" fill=\"#9EAAB2\" d=\"M3.5 3h10a.5.5 0 01.5.5v14.518a.5.5 0 01-.794.404l-4.412-3.208a.5.5 0 00-.588 0l-4.412 3.208A.5.5 0 013 18.018V3.5a.5.5 0 01.5-.5z\"/><circle class=\"fill-white\" fill=\"#fff\" cx=\"13.5\" cy=\"9.5\" r=\"4.5\"/><circle class=\"fill-none stroke-current\" cx=\"13.5\" cy=\"9.5\" r=\"5\" stroke=\"#9EAAB2\"/><path class=\"fill-current\" fill=\"#9EAAB2\" d=\"M12.793 11.856l-2.121-2.12.707-.708 1.414 1.414 2.828-2.828.707.707z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "19",
      "height": "19",
      "viewBox": "0 0 19 19"
    }
  };
  _exports.default = _default;
});