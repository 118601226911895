define("ember-svg-jar/inlined/cancel-circle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g class=\"fill-current\" fill=\"#231f20\"><path d=\"M16.843 8.136a.5.5 0 00-.707 0l-3.646 3.646-3.647-3.646a.5.5 0 00-.707.707l3.646 3.646-3.646 3.646a.5.5 0 00.708.707l3.646-3.646 3.646 3.646a.498.498 0 00.708 0 .5.5 0 000-.707l-3.646-3.646 3.646-3.646a.501.501 0 00-.001-.707z\"/><path d=\"M12.5.5C5.883.5.5 5.883.5 12.5s5.383 12 12 12 12-5.383 12-12-5.383-12-12-12zm0 23c-6.065 0-11-4.935-11-11s4.935-11 11-11 11 4.935 11 11-4.935 11-11 11z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 25 25"
    }
  };
  _exports.default = _default;
});