define("ember-svg-jar/inlined/sort-asc-letters", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g class=\"fill-current\" fill=\"#90A4AE\"><path d=\"M9 3H2V2h7zM6 1H2V0h4zM12 5H2V4h10zM15 7H2V6h13zM2.423 15l.378-1.253h1.963L5.142 15H6.33L4.366 9.182h-1.17L1.233 15h1.19zm1.324-4.39h.074l.67 2.231H3.074l.673-2.23zm6.026 1.06H6.989v.824h2.784v-.823zm4.942 2.401h-2.82l2.803-4.179v-.71h-4.153v.929h2.82l-2.803 4.176V15h4.153v-.929z\"/></g>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});