define("ember-freestyle/components/freestyle-showdown-content", ["exports", "ember-freestyle/templates/components/freestyle-showdown-content"], function (_exports, _freestyleShowdownContent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _freestyleShowdownContent.default,
    emberFreestyle: Ember.inject.service(),

    didInsertElement() {
      let pre = this.get('element').getElementsByTagName('pre');

      if (pre[0]) {
        this.get('emberFreestyle').highlight(pre[0]);
      }
    }

  });

  _exports.default = _default;
});