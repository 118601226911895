define("ember-svg-jar/inlined/transaction-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M4.96 14.908c.616.356 1.274.631 1.959.82l.263-.942a7.055 7.055 0 01-1.713-.725l-.508.847zM2.014 11.896c.343.624.771 1.197 1.271 1.704l.716-.697a7.227 7.227 0 01-1.12-1.496l-.867.49zM14.656 2.344A7.925 7.925 0 009 0 8.002 8.002 0 00.998 8H0l1.497 1.995L3.003 8h-.998A6.992 6.992 0 018.999.998 7.004 7.004 0 0113.95 12.95a7.004 7.004 0 01-4.952 2.051V16a8.011 8.011 0 007.397-11.064 7.924 7.924 0 00-1.74-2.592z\" fill=\"#90A4AE\" class=\"fill-current\"/><path d=\"M7.5 9.5v1h3v-2l-3-1v-2h3v1\" stroke=\"#90A4AE\" class=\"stroke-current\" stroke-linecap=\"square\" stroke-linejoin=\"round\"/><path fill=\"#90A4AE\" class=\"fill-current\" d=\"M8.5 4h1v1.5h-1zM8.5 10.5h1V12h-1z\"/>",
    "attrs": {
      "width": "17",
      "height": "16",
      "viewBox": "0 0 17 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});