define("ember-svg-jar/inlined/sort-asc-numbers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g class=\"fill-current\" fill=\"#90A4AE\"><path d=\"M9 4H2V3h7zM6 2H2V1h4zM12 6H2V5h10zM15 8H2V7h13zM6.403 11.58c0-1.654-.858-2.478-2.022-2.478-1.165 0-2.026.824-2.026 2.478v.948c0 1.716.858 2.552 2.029 2.552 1.164 0 2.02-.836 2.02-2.552v-.948zm-1.082 1.105c0 1.008-.403 1.474-.937 1.474-.54 0-.95-.466-.95-1.474v-1.259c0-.954.407-1.412.947-1.412s.94.458.94 1.412v1.259zm4.647-1.014H7.184v.823h2.784v-.823zm1.88 1.818h-1.031c.199.96.758 1.608 1.832 1.608 1.472-.066 2.037-1.282 2.037-3.122v-.216c0-1.716-.767-2.654-2.008-2.654-1.168 0-1.924.847-1.924 1.946 0 1.043.551 1.91 1.642 1.91.495 0 .924-.191 1.222-.504-.054 1.18-.449 1.733-.957 1.742-.515 0-.685-.298-.813-.71zm-.04-2.452c0-.617.364-1.054.872-1.054.537 0 .924.372.924 1.165v.028c0 .492-.341.91-.91.91-.582 0-.886-.441-.886-1.049z\"/></g>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});