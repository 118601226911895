define("ember-intl/macros/t", ["exports", "ember-intl/-private/utils/empty-object", "ember-intl/macros/intl"], function (_exports, _emptyObject, _intl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.raw = raw;
  _exports.default = createTranslatedComputedProperty;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function partitionDynamicValuesAndStaticValues(options) {
    const dynamicValues = new _emptyObject.default();
    const staticValues = new _emptyObject.default();
    Object.keys(options).forEach(key => {
      const value = options[key];

      if (value instanceof Raw) {
        staticValues[key] = value.valueOf();
      } else {
        dynamicValues[key] = value;
      }
    });
    return [dynamicValues, staticValues];
  }

  function mapPropertiesByHash(object, hash) {
    const result = new _emptyObject.default();
    Object.keys(hash).forEach(key => {
      result[key] = Ember.get(object, hash[key]);
    });
    return result;
  }
  /**
   * This class is used to box primitive types and mark them as raw literals that
   * should be used as is by the translation macro.
   *
   * This class is internal. Instead of using this class directly, use the `raw`
   * utility function, that creates an instance of this class.
   */


  class Raw {
    constructor(value) {
      _defineProperty(this, "_value", void 0);

      this._value = value;
    }

    valueOf() {
      return this._value;
    }

    toString() {
      return String(this._value);
    }

  }
  /**
   * Use this utility function to mark a value as a raw literal.
   *
   * @param {*} value The value to mark as a raw literal.
   * @return The same value, but boxed in the `Raw` class so that the consuming
   *  macro understands that this value should be used as is.
   */


  function raw(value) {
    return new Raw(value);
  }

  function createTranslatedComputedProperty(translationKey, options) {
    const hash = options || new _emptyObject.default();
    const [dynamicValues, staticValues] = partitionDynamicValuesAndStaticValues(hash);
    const dependentKeys = Object.values(dynamicValues); // @ts-ignore TS2556: Can't deal with leading rest arguments

    return (0, _intl.default)(...dependentKeys, (intl, propertyKey, ctx) => intl.t(translationKey, { ...staticValues,
      ...mapPropertiesByHash(ctx, dynamicValues)
    }));
  }
});