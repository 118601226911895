define("ember-svg-jar/inlined/success-check", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>Fill 432 + Fill 433</title><defs><filter x=\"-50%\" y=\"-50%\" width=\"200%\" height=\"200%\" filterUnits=\"objectBoundingBox\" id=\"a\"><feOffset dy=\"1\" in=\"SourceAlpha\" result=\"shadowOffsetOuter1\"/><feGaussianBlur in=\"shadowOffsetOuter1\" result=\"shadowBlurOuter1\"/><feColorMatrix values=\"0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.35 0\" in=\"shadowBlurOuter1\" result=\"shadowMatrixOuter1\"/><feMerge><feMergeNode in=\"shadowMatrixOuter1\"/><feMergeNode in=\"SourceGraphic\"/></feMerge></filter><filter x=\"-50%\" y=\"-50%\" width=\"200%\" height=\"200%\" filterUnits=\"objectBoundingBox\" id=\"b\"><feOffset dy=\"1\" in=\"SourceAlpha\" result=\"shadowOffsetOuter1\"/><feGaussianBlur in=\"shadowOffsetOuter1\" result=\"shadowBlurOuter1\"/><feColorMatrix values=\"0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.35 0\" in=\"shadowBlurOuter1\" result=\"shadowMatrixOuter1\"/><feMerge><feMergeNode in=\"shadowMatrixOuter1\"/><feMergeNode in=\"SourceGraphic\"/></feMerge></filter></defs><g stroke=\"#FFF\" fill=\"#F7F9FA\" fill-rule=\"evenodd\"><path d=\"M20 0C8.956 0 0 8.953 0 20s8.955 19.998 20 20c11.046 0 20-8.953 20-20S31.046 0 20 0h0zm12.965 32.963A18.274 18.274 0 0120 38.333a18.274 18.274 0 01-12.964-5.37A18.272 18.272 0 011.667 20c0-5.065 2.051-9.643 5.37-12.963a18.267 18.267 0 0112.964-5.37c5.065 0 9.644 2.05 12.964 5.37A18.272 18.272 0 0138.335 20c0 5.065-2.052 9.643-5.37 12.963h0z\" filter=\"url(#a)\" transform=\"translate(1 1)\"/><path d=\"M28.559 14.251L16.085 26.927l-4.644-4.719a.835.835 0 00-1.194 0 .868.868 0 000 1.213l5.242 5.327a.832.832 0 001.193 0l13.072-13.282a.874.874 0 000-1.215.837.837 0 00-1.195 0\" filter=\"url(#b)\" transform=\"translate(1 1)\"/></g>",
    "attrs": {
      "width": "42",
      "height": "43",
      "viewBox": "0 0 42 43",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});