define("ember-svg-jar/inlined/percentage", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#40555B\" fill-rule=\"evenodd\" d=\"M12.153 1.133l1.093 1.092-12.02 12.021-1.093-1.093 12.02-12.02zM3.957 4.957A2.318 2.318 0 11.68 1.68a2.318 2.318 0 013.278 3.278zM12.7 13.7A2.318 2.318 0 119.42 10.42 2.318 2.318 0 0112.7 13.7z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16"
    }
  };
  _exports.default = _default;
});