define("ember-svg-jar/inlined/notes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M5 2a2 2 0 114 0v3a2 2 0 11-4 0V2z\" fill=\"#fff\"/><path fill=\"#fff\" stroke=\"#9EAAB2\" d=\"M2.488 2.734l11.97-.846.82 11.618-11.97.846z\"/><path fill=\"#fff\" stroke=\"#9EAAB2\" d=\"M2.475 1.597l11.84 1.953-1.79 10.853L.685 12.45z\"/><path d=\"M8 6V2a1 1 0 10-2 0v.5H5V2a2 2 0 114 0v4a2 2 0 11-4 0V4.5a.5.5 0 011 0V6a1 1 0 002 0z\" fill=\"#9EAAB2\"/>",
    "attrs": {
      "width": "14",
      "height": "14",
      "viewBox": "0 0 16 15",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});