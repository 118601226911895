define("ember-freestyle/components/freestyle-usage-controls", ["exports", "ember-freestyle/templates/components/freestyle-usage-controls"], function (_exports, _freestyleUsageControls) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _freestyleUsageControls.default,
    tagName: '',
    emberFreestyle: Ember.inject.service(),
    showLabels: Ember.computed.alias('emberFreestyle.showLabels'),
    showNotes: Ember.computed.alias('emberFreestyle.showNotes'),
    showCode: Ember.computed.alias('emberFreestyle.showCode'),
    showHeader: true,
    showFocus: true,

    didInsertElement() {
      this._super(...arguments);

      Ember.run.schedule('afterRender', () => {
        this.set('focus', this.get('emberFreestyle.focus'));
      });
    },

    actions: {
      toggleUsage(usageType) {
        let usageProperty = `emberFreestyle.show${Ember.String.capitalize(usageType)}`;
        this.toggleProperty(usageProperty);
      },

      setFocus() {
        this.set('emberFreestyle.focus', this.get('focus'));
      }

    }
  });

  _exports.default = _default;
});