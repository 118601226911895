define("ember-svg-jar/inlined/chevron", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M5 6.9l4.95-4.95L8.536.536 5 4.07 1.464.536.05 1.95 5 6.9z\" fill=\"#9EAAB2\" class=\"fill-current\"/>",
    "attrs": {
      "width": "10",
      "height": "7",
      "viewBox": "0 0 10 7",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});