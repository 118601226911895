define("ember-svg-jar/inlined/help", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M18 36c9.941 0 18-8.059 18-18S27.941 0 18 0 0 8.059 0 18s8.059 18 18 18z\" fill=\"#CFD8DC\" class=\"fill-current\"/><path d=\"M16.068 17.79h1.017c2.644 0 3.864-1.255 3.864-3.348 0-1.884-1.017-3.14-2.644-3.14-1.627 0-2.847 1.047-3.457 3.559L12 14.232C12.61 10.883 14.644 9 18.102 9 21.762 9 24 11.302 24 14.442c0 2.93-1.83 4.814-5.085 5.232v1.884h-2.847v-3.767zm3.05 5.652V27h-3.254v-3.558h3.255z\" fill=\"#fff\"/>",
    "attrs": {
      "width": "36",
      "height": "36",
      "viewBox": "0 0 36 36",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});