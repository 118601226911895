define("ember-freestyle/components/freestyle-typeface", ["exports", "ember-freestyle/templates/components/freestyle-typeface"], function (_exports, _freestyleTypeface) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _freestyleTypeface.default,
    classNames: ['FreestyleTypeface'],
    attributeBindings: ['fontFamilyStyle:style'],
    fontFamilyStyle: Ember.computed('fontFamily', function () {
      return Ember.String.htmlSafe(`font-family: ${this.get('fontFamily')};`);
    })
  });

  _exports.default = _default;
});