define("ember-set-helper/helpers/set", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function set(positional) {
    let [target, path, maybeValue] = positional;
    (false && !(typeof path === 'string' && path.length > 0 || typeof path === 'symbol' || typeof path === 'number') && Ember.assert('you must pass a path to {{set}}. You can pass a path statically, as in `{{set this.foo}}`, or with the path argument dynamically, as in `{{set this path="foo"}}`', typeof path === 'string' && path.length > 0 || typeof path === 'symbol' || typeof path === 'number'));
    return positional.length === 3 ? () => Ember.set(target, path, maybeValue) : value => Ember.set(target, path, value);
  }

  var _default = Ember.Helper.helper(set);

  _exports.default = _default;
});