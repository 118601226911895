define("ember-svg-jar/inlined/budget-needs-approval", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\" transform=\"translate(-.5 -.379)\"><circle cx=\"15.08\" cy=\"15.08\" r=\"14.286\" fill=\"#03A9F4\"/><path fill=\"#FFF\" d=\"M13.024 21.034l-4.04-4.041-1.347 1.347-.674 4.714 4.715-.674 1.346-1.346zm1.01-1.01l8.082-8.082-4.04-4.04-8.082 8.08 4.04 4.041zM23.126 10.933l-4.04-4.04a2.857 2.857 0 114.04 4.04z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "29",
      "height": "29",
      "viewBox": "0 0 29 29"
    }
  };
  _exports.default = _default;
});