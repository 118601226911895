define("ember-svg-jar/inlined/accounts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"#010101\"><path d=\"M21.195 4.947l-.001-.6c0-.827-.673-1.5-1.5-1.5h-.476v-.608c0-.827-.673-1.5-1.5-1.5H7.282c-.827 0-1.5.673-1.5 1.5v.608h-.476c-.827 0-1.5.673-1.5 1.5v.6h-.001c-.827 0-1.5.673-1.5 1.5v16.701c0 .827.673 1.5 1.5 1.5h17.391c.827 0 1.5-.673 1.5-1.5V6.447a1.503 1.503 0 00-1.501-1.5zM6.782 2.239a.5.5 0 01.5-.5h10.436a.5.5 0 01.5.5v.608H6.782v-.608zM4.806 4.347a.5.5 0 01.5-.5H19.694a.5.5 0 01.5.5v.6H4.806v-.6zm16.889 18.8a.5.5 0 01-.5.5H3.805a.5.5 0 01-.5-.5v-16.7a.5.5 0 01.5-.5H21.195a.5.5 0 01.5.5v16.7z\"/><path d=\"M12.039 12.972a.908.908 0 01-.323-.69.856.856 0 01.309-.705.923.923 0 01.749-.206c.265.044.5.206.632.44a.72.72 0 00.97.265.702.702 0 00.25-.97c-.279-.5-.75-.882-1.293-1.043v-.588a.715.715 0 00-.706-.705.705.705 0 00-.705.705v.573c-.294.103-.573.25-.808.455a2.267 2.267 0 00-.808 1.793c0 .69.308 1.337.852 1.764l1.837 1.513a.97.97 0 01.367.735.96.96 0 01-.353.749.965.965 0 01-1.469-.25.7.7 0 00-.955-.265.72.72 0 00-.264.971c.309.528.793.91 1.366 1.087v.573c0 .396.309.705.706.705a.705.705 0 00.705-.705V18.6c.294-.103.588-.25.838-.471a2.406 2.406 0 00.837-1.836 2.39 2.39 0 00-.882-1.808l-1.852-1.513z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 25 25"
    }
  };
  _exports.default = _default;
});