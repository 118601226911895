define("ember-svg-jar/inlined/district-cancel2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M9 4.963h4v-1H9v1zm4 2v1h-2.172l2 2H13v.172l2 2V1.963a1 1 0 00-1-1H9a1 1 0 00-1 1v2H6.828l3 3H13zm-10 4h4v-1H3v1zm0 3h4v-1H3v1zm6 1v-2h1.172l-5-5H3v-1h1.172L2.029 4.82c-.008.048-.029.092-.029.143v10H.5a.5.5 0 000 1h12.672l-1-1H9zm6.723-.618L1.656.277A.946.946 0 00.986 0H.985a.946.946 0 00-.67 1.617l14.068 14.07a.937.937 0 00.669.276.948.948 0 00.67-1.617\" fill=\"#9EAAB2\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16"
    }
  };
  _exports.default = _default;
});