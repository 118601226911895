define("ember-toggle/components/x-toggle-switch/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qEU9pGPK",
    "block": "{\"symbols\":[\"@value\",\"@disabled\",\"@size\",\"&attrs\",\"@toggled\",\"@forId\",\"@name\",\"@sendToggle\",\"@onLabel\",\"@offLabel\"],\"statements\":[[11,\"span\"],[16,0,[31,[\"x-toggle-container\\n    \",[32,3],\"\\n    \",[30,[36,0],[[32,2],\"x-toggle-container-disabled\"],null],\"\\n    \",[30,[36,0],[[32,1],\"x-toggle-container-checked\"],null],\"\\n  \"]]],[17,4],[4,[38,2],null,[[\"onPanStart\",\"onPan\",\"pointerTypes\"],[[32,0,[\"handlePan\"]],[32,0,[\"handlePan\"]],[30,[36,1],[\"touch\",\"mouse\"],null]]]],[12],[2,\"\\n  \"],[11,\"input\"],[24,0,\"x-toggle\"],[16,\"checked\",[32,5]],[16,\"disabled\",[32,2]],[16,1,[32,6]],[16,3,[32,7]],[24,4,\"checkbox\"],[4,[38,4],[\"change\",[30,[36,3],[[32,0],[32,8]],[[\"value\"],[\"target.checked\"]]]],null],[12],[13],[2,\"\\n\\n  \"],[10,\"label\"],[15,\"for\",[32,0,[\"effectiveForId\"]]],[12],[2,\"\\n    \"],[10,\"div\"],[15,1,[31,[\"x-toggle-visual-\",[32,6]]]],[14,\"role\",\"checkbox\"],[15,0,[31,[\"x-toggle-btn\\n        \",[32,0,[\"themeClass\"]],\"\\n        \",[32,0,[\"variant\"]],\"\\n        \",[32,3],\"\\n        \",[30,[36,0],[[32,2],\" x-toggle-disabled\"],null],\"\\n      \"]]],[15,\"aria-owns\",[32,6]],[15,\"aria-checked\",[32,5]],[15,\"data-tg-on\",[32,9]],[15,\"data-tg-off\",[32,10]],[12],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"if\",\"array\",\"did-pan\",\"action\",\"on\"]}",
    "moduleName": "ember-toggle/components/x-toggle-switch/template.hbs"
  });

  _exports.default = _default;
});