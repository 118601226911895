define("ember-svg-jar/inlined/district", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#9EAAB2\" fill-rule=\"evenodd\" d=\"M9 5h4V4H9v1zm0 3h4V7H9v1zm0 3h4v-1H9v1zm0 4h4v-2H9v2zM3 8h4V7H3v1zm0 3h4v-1H3v1zm0 3h4v-1H3v1zm12.5 1H15V2a1 1 0 00-1-1H9a1 1 0 00-1 1v2H3a1 1 0 00-1 1v10H.5a.5.5 0 000 1h15a.5.5 0 000-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16"
    }
  };
  _exports.default = _default;
});