define("ember-one-way-select/templates/components/one-way-select/option", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ISIP3pX7",
    "block": "{\"symbols\":[\"@option\",\"@optionTargetPath\",\"@optionValuePath\",\"@optionLabelPath\",\"@groupIndex\",\"@index\",\"@optionComponent\",\"&default\",\"@selected\"],\"statements\":[[10,\"option\"],[15,2,[30,[36,1],[[32,3],[30,[36,0],[[32,1],[32,3]],null],[32,1]],null]],[15,\"selected\",[30,[36,3],[[32,9],[32,1],[32,3],[32,2]],null]],[12],[2,\"\\n\"],[6,[37,1],[[27,[32,8]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[18,8,null],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[32,7]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,2],[[32,7]],[[\"option\",\"index\",\"groupIndex\"],[[32,1],[32,6],[32,5]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[32,4]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,0],[[32,1],[32,4]],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[32,3]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,0],[[32,1],[32,3]],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[32,2]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,0],[[32,1],[32,2]],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[1,[32,1]],[2,\"\\n  \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"get\",\"if\",\"component\",\"one-way-select/contains\"]}",
    "moduleName": "ember-one-way-select/templates/components/one-way-select/option.hbs"
  });

  _exports.default = _default;
});