define("ember-svg-jar/inlined/notification-info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#FFF\" class=\"fill-current\" fill-opacity=\".75\" d=\"M8 16A8 8 0 118 0a8 8 0 010 16zm1.7-4.516c0-.043-.043-.12-.128-.232-.085-.112-.165-.168-.24-.168-.085 0-.285.168-.6.504-.315.336-.59.504-.824.504a.213.213 0 01-.096-.024c-.032-.016-.048-.056-.048-.12 0-.053.021-.15.064-.288.043-.139.112-.32.208-.544l1.216-2.672c.107-.192.176-.379.208-.56.032-.181.048-.352.048-.512 0-.384-.12-.712-.36-.984s-.6-.408-1.08-.408c-.299 0-.643.107-1.032.32-.39.213-.723.443-1 .688a6.92 6.92 0 00-.768.76c-.203.24-.304.413-.304.52 0 .032.043.107.128.224s.17.176.256.176c.075 0 .312-.21.712-.632.4-.421.67-.632.808-.632a.16.16 0 01.128.056c.032.037.048.099.048.184a.953.953 0 01-.056.296 4.255 4.255 0 01-.184.44l-1.52 3.152c-.064.16-.123.317-.176.472-.053.155-.08.323-.08.504 0 .288.101.547.304.776.203.23.528.344.976.344.65 0 1.379-.315 2.184-.944.805-.63 1.208-1.03 1.208-1.2zM9.4 5.3a1.4 1.4 0 100-2.8 1.4 1.4 0 000 2.8z\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16"
    }
  };
  _exports.default = _default;
});