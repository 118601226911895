define("ember-inputmask/components/one-way-number-mask", ["exports", "ember-inputmask/components/one-way-input-mask"], function (_exports, _oneWayInputMask) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DEFAULT_OPTIONS = {
    groupSeparator: ',',
    radixPoint: '.',
    groupSize: '3',
    autoGroup: true
  };
  /**
   * @class OneWayNumberMask
   */

  var _default = _oneWayInputMask.default.extend({
    NON_ATTRIBUTE_BOUND_PROPS: _oneWayInputMask.DEFAULT_NON_BOUND_PROPS.concat('decimal'),

    /**
     * @field mask
     * @override
     */
    mask: 'integer',

    /**
     * Set this to true to include decimals
     *
     * @argument decimal
     * @type Boolean
     */
    decimal: false,

    init() {
      this._super(...arguments);

      Ember.set(this, '_options', Ember.assign({}, this._options, DEFAULT_OPTIONS));

      if (this.decimal) {
        Ember.set(this, 'mask', 'decimal'); // Give default digits if we don't have them already

        if (!this.options || Ember.isBlank(this.options.digits)) {
          Ember.set(this, '_options.digits', 2);
        }
      }
    }

  });

  _exports.default = _default;
});