define("ember-svg-jar/inlined/bookmark-added", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M17.092 5.215c-.084 0-.165.009-.248.013V1H2.075l-.014 23 7.392-4.76L16.844 24v-6.104c.083.003.165.013.248.013 3.5 0 6.347-2.847 6.347-6.347s-2.847-6.347-6.347-6.347zm0 11.693c-2.948 0-5.347-2.398-5.347-5.347s2.398-5.347 5.347-5.347 5.347 2.398 5.347 5.347-2.398 5.347-5.347 5.347z\"/><path d=\"M19.461 9.672l-3.522 3.214-1.667-1.433a.5.5 0 00-.651.759l2.338 2.01 4.177-3.812a.5.5 0 00-.675-.738z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 25 25"
    }
  };
  _exports.default = _default;
});