define("ember-svg-jar/inlined/breadcrumb-left", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>breadcrumb-left</title><defs><filter x=\"-50%\" y=\"-50%\" width=\"200%\" height=\"200%\" filterUnits=\"objectBoundingBox\" id=\"b\"><feOffset dy=\"1\" in=\"SourceAlpha\" result=\"shadowOffsetOuter1\"/><feGaussianBlur stdDeviation=\"1\" in=\"shadowOffsetOuter1\" result=\"shadowBlurOuter1\"/><feComposite in=\"shadowBlurOuter1\" in2=\"SourceAlpha\" operator=\"out\" result=\"shadowBlurOuter1\"/><feColorMatrix values=\"0 0 0 0 0.237563566 0 0 0 0 0.411860529 0 0 0 0 0.652556335 0 0 0 1 0\" in=\"shadowBlurOuter1\"/></filter><path d=\"M2 3.006C2 1.898 2.895 1 3.996 1H149.2c1.102 0 2.565.685 3.27 1.534L163 15.205l-10.553 13.098c-.691.858-2.147 1.554-3.248 1.554H3.996A2 2 0 012 27.851V3.006z\" id=\"a\"/><mask id=\"c\" maskContentUnits=\"userSpaceOnUse\" maskUnits=\"objectBoundingBox\" x=\"0\" y=\"0\" width=\"161\" height=\"28.857\" fill=\"#fff\"><use xlink:href=\"#a\"/></mask></defs><g fill=\"none\" fill-rule=\"evenodd\"><use fill=\"#000\" filter=\"url(#b)\" xlink:href=\"#a\"/><use stroke=\"#F9FEFF\" mask=\"url(#c)\" stroke-width=\"2\" fill=\"#FFF\" xlink:href=\"#a\"/></g>",
    "attrs": {
      "width": "5",
      "height": "33",
      "viewBox": "0 0 5 33",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink"
    }
  };
  _exports.default = _default;
});