define("ember-svg-jar/inlined/na-bookmarks", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g data-name=\"Layer 2\"><g data-name=\"Layer 1\"><path d=\"M85.62 53.56L51.46 33.81 6.86 59.56l19 11a3.76 3.76 0 01-.67.52l-4 2.32h5.89v3.51l4.06-2.37a3.5 3.5 0 00.68-.52L41 79.31z\" fill=\"#f2f5f9\"/><path fill=\"#dce2ea\" d=\"M40.44 60.74L2.48 38.8v-1.17l49.56-27.44L90 30.96v1.17L40.44 60.74z\"/><path fill=\"#cfd7e2\" d=\"M40.44 59.57L2.48 37.63 52.04 9.02 90 30.96 40.44 59.57z\"/><path fill=\"#e9edf4\" d=\"M40.37 51.32L.97 29.45l7.22-4.17L49.43 1.47l39.41 21.87-48.47 27.98z\"/><path d=\"M40.37 58.69L2.76 37A6.62 6.62 0 010 31.69c0-2 1.24-3 2.76-2.09l37.61 21.72z\" fill=\"#e9edf4\"/><path d=\"M88.82 23.35zm-3.2 1.85L40.37 51.32c-1.77-1-3.21-.21-3.21 1.83a7.11 7.11 0 003.21 5.55l48.44-28a7.09 7.09 0 01-3.19-5.5z\" fill=\"#dce2ea\"/><path fill=\"#dce2ea\" d=\"M40.44 51.72L2.48 29.78v-1.17L52.04 1.17 90 21.94v1.17L40.44 51.72z\"/><path fill=\"#cfd7e2\" d=\"M40.44 50.55L2.48 28.61 52.04 0 90 21.94 40.44 50.55zM26.87 46a4.09 4.09 0 00-2 3.53 4.08 4.08 0 01-2 3.52l-4.42 2.58h6.54v3.89l4.51-2.63a4.06 4.06 0 002-3.52 4.08 4.08 0 012-3.53z\"/></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 90 79.31"
    }
  };
  _exports.default = _default;
});