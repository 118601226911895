define("ember-svg-jar/inlined/warning-exclamation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<circle cx=\"8\" cy=\"8\" r=\"8\" fill=\"#FFFDE7\"/><path d=\"M7 3.286V2h2v1.286l-.353 7.143H7.353L7 3.286zm0 8.285h2V14H7v-2.429z\" fill=\"#FFAB40\"/>",
    "attrs": {
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});