define("ember-svg-jar/inlined/search-magnifying-small", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#DCE2EA\" fill-rule=\"evenodd\" d=\"M9 5.5C9 7.43 7.43 9 5.5 9S2 7.43 2 5.5 3.57 2 5.5 2 9 3.57 9 5.5zm4 6.5c0-.266-.11-.523-.29-.703l-2.679-2.68A5.498 5.498 0 005.5 0C2.46 0 0 2.46 0 5.5a5.498 5.498 0 008.617 4.531l2.68 2.672A.98.98 0 0012 13c.547 0 1-.453 1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "13",
      "height": "13",
      "viewBox": "0 0 13 13"
    }
  };
  _exports.default = _default;
});