define("ember-svg-jar/inlined/na-expenses", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g data-name=\"Layer 2\"><g data-name=\"Layer 1\"><path fill=\"#f2f5f9\" d=\"M2.99 77.39l28.4 16.29 5.05-2.96-28.4-16.3-5.05 2.97zm6.65-3.83l28.39 16.3 5.06-2.97-28.4-16.3-5.05 2.97zm6.35-3.7l28.39 16.3 12.45-7.5-28.4-16.29-12.44 7.49z\"/><path d=\"M28.45 52.58A40.55 40.55 0 0116.57 23.9L14.26 1.33 16.57 0l31.55 18.1V42A40.53 40.53 0 0060 70.68L57.69 72z\" fill=\"#dce2ea\"/><path d=\"M26.14 53.91a40.55 40.55 0 01-11.88-28.68V1.33l31.55 18.1v23.9A40.53 40.53 0 0057.69 72z\" fill=\"#cfd7e2\"/><path fill=\"#dce2ea\" d=\"M41.02 80.89l-2.38 1.33L9.51 62.7 7.13 5.47l2.38-1.33 31.51 18.2v58.55z\"/><path fill=\"#e9edf4\" d=\"M38.64 82.22L7.13 64.03V5.47l31.51 18.2v58.55z\"/><path fill=\"#dce2ea\" d=\"M33.89 85.04l-2.38 1.33L2.38 66.85 0 9.62l2.38-1.33 31.51 18.19v58.56z\"/><path fill=\"#e9edf4\" d=\"M31.51 86.37L0 68.18V9.62l31.51 18.19v58.56z\"/></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 60 93.68"
    }
  };
  _exports.default = _default;
});