define("ember-svg-jar/inlined/chevron-double-down", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"#21A9F2\" fill-rule=\"evenodd\"><path d=\"M.967 3.265l2.546 2.546-.88.88L.087 4.145z\"/><path d=\"M5.913 4.145L3.367 6.691l-.88-.88 2.546-2.546zM.968.154l2.545 2.545-.88.88L.089 1.033z\"/><path d=\"M5.913 1.035L3.368 3.58l-.88-.88L5.034.156z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "6",
      "height": "7",
      "viewBox": "0 0 6 7"
    }
  };
  _exports.default = _default;
});