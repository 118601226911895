define("ember-light-table/templates/components/lt-scrollable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FRK8F4ud",
    "block": "{\"symbols\":[\"scrollbar\",\"&default\"],\"statements\":[[6,[37,7],[[35,6]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,5],null,[[\"classNames\",\"autoHide\",\"horizontal\",\"vertical\",\"scrollToY\",\"onScrollY\"],[\"lt-scrollable\",[35,4],[35,3],[35,2],[35,1],[35,0]]],[[\"default\"],[{\"statements\":[[2,\"    \"],[18,2,[[32,1]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[18,2,null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"onScrollY\",\"scrollTo\",\"vertical\",\"horizontal\",\"autoHide\",\"ember-scrollable\",\"virtualScrollbar\",\"if\"]}",
    "moduleName": "ember-light-table/templates/components/lt-scrollable.hbs"
  });

  _exports.default = _default;
});