define("ember-svg-jar/inlined/na-job-types", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g data-name=\"Layer 2\"><g data-name=\"Layer 1\"><path d=\"M57.94 100.27l11.47-6.62a1.18 1.18 0 000-2.05L18 61.69a3 3 0 00-3 0L3.52 68.31a1.18 1.18 0 000 2l51.41 29.92a3 3 0 003.01.04z\" fill=\"#f2f5f9\"/><path d=\"M9 16.43v29.31a7.55 7.55 0 003.16 6l47.4 27.37-6 3.48 6.9 4 6.86-4a2.69 2.69 0 001.17-2.57V50.74z\" fill=\"#cfd7e2\"/><path d=\"M58.24 86.5L5.11 55.83a7.54 7.54 0 01-3.15-6V20.5l59.43 34.31v29.31c0 2.32-1.39 3.39-3.15 2.38z\" fill=\"#e9edf4\"/><path d=\"M66.17 32.82L9.92.35a1.83 1.83 0 00-2-.1l-6.78 3.9L7 7.52v19.33a5.68 5.68 0 002.38 4.54l57.36 33.13c1.31.76 2.38 0 2.38-1.79V38.46a7.07 7.07 0 00-2.95-5.64z\" fill=\"#dce2ea\"/><path fill=\"#cfd7e2\" d=\"M15.17 26.62l-11.09-6.4 11.02-6.37 11.09 6.41-11.02 6.36zM32 55.74l-5.24-3c-.51-.29-.92 0-.92.7v5.68a2.23 2.23 0 00.92 1.77l4.49 2.59-.78.45 1.09.63 1.06-.61a.83.83 0 00.31-.74v-5.7a2.21 2.21 0 00-.93-1.77zm14.86-38.67a3.54 3.54 0 00-1.48-2.84L27.08 3.67l-.24-.14a1 1 0 00-1-.08L22.3 5.51a1.28 1.28 0 00-.54 1.2.91.91 0 000 .17v9.52l3.45 2 3.57-2.08v-3.56l11 6.37v7.59l-.08.14 3.49 2 3.53-2h.06v-9.64c0-.06.08-.1.08-.15z\"/><path fill=\"#dce2ea\" d=\"M61.34 68.66l-6.9-3.98 6.85-3.95 6.9 3.98-6.85 3.95z\"/><path d=\"M3 4.37l56.2 32.48a7.05 7.05 0 012.95 5.63V74c0 1.75-1.07 2.55-2.38 1.79L2.38 42.7A5.68 5.68 0 010 38.16V6.6c0-2.17 1.32-3.17 3-2.23z\" fill=\"#dce2ea\"/><path d=\"M31 64.47l-5.25-3a2.2 2.2 0 01-.92-1.77V54c0-.68.41-1 .92-.7l5.25 3a2.21 2.21 0 01.92 1.77v5.67c-.03.71-.44 1.03-.92.73z\" fill=\"#f2f5f9\"/><path d=\"M65 80.51c-1.56.91-2.84-.06-2.84-2.15V42.71A6.84 6.84 0 0165 37.27c1.57-.9 2.85.06 2.85 2.15v35.65A6.83 6.83 0 0165 80.51z\" fill=\"#cfd7e2\"/><path d=\"M68.49 65.52c-.35.2-.63 0-.63-.48v-7.9a1.5 1.5 0 01.63-1.2c.34-.21.63 0 .63.47v7.9a1.53 1.53 0 01-.63 1.21z\" fill=\"#dce2ea\"/></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 70 100.67"
    }
  };
  _exports.default = _default;
});