define("ember-svg-jar/inlined/sort-desc-letters", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g class=\"fill-current\" fill=\"#90A4AE\"><path d=\"M8 4h7v1H8zM11 6h4v1h-4zM5 2h10v1H5zM2 0h13v1H2zM5.551 14.071h-2.82l2.803-4.179v-.71H1.381v.929H4.2l-2.803 4.176V15H5.55v-.929zm3.589-2.4H6.356v.823H9.14v-.823zM10.954 15l.378-1.254h1.963L13.674 15h1.188l-1.963-5.818h-1.17L9.763 15h1.19zm1.324-4.39h.074l.67 2.23h-1.417l.673-2.23z\"/></g>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});