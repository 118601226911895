define("ember-svg-jar/inlined/lock-green", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>Lock - Green</title><g fill=\"none\" fill-rule=\"evenodd\"><path d=\"M1.444 4.826A4.83 4.83 0 016.264 0h.473a4.826 4.826 0 014.819 4.826v4.826H1.444V4.826z\" fill=\"#25AB76\"/><path d=\"M4.333 4.92a2.168 2.168 0 014.333 0v4.732H4.334V4.92z\" fill=\"#FFF\"/><path fill=\"#25AB76\" d=\"M0 6.894h13v8.273H0z\"/></g>",
    "attrs": {
      "width": "13",
      "height": "16",
      "viewBox": "0 0 13 16",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});