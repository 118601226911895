define("ember-svg-jar/inlined/open", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>Group 3 Copy 2</title><defs><rect id=\"a\" x=\"0\" y=\"0\" width=\"24\" height=\"24\" rx=\"12\"/><mask id=\"b\" maskContentUnits=\"userSpaceOnUse\" maskUnits=\"objectBoundingBox\" x=\"0\" y=\"0\" width=\"24\" height=\"24\" fill=\"#fff\"><use xlink:href=\"#a\"/></mask></defs><g stroke=\"#FFF\" fill=\"none\" fill-rule=\"evenodd\"><use mask=\"url(#b)\" stroke-width=\"6\" xlink:href=\"#a\"/><path d=\"M8.333 12.5h7.334M12 16.167V8.833\" stroke-width=\"3\" stroke-linecap=\"square\"/></g>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink"
    }
  };
  _exports.default = _default;
});