define("ember-svg-jar/inlined/change-log", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path stroke=\"#9EAAB2\" stroke-width=\".5\" d=\"M8.25 9.25h1.5v.5h-1.5z\"/><path fill=\"#fff\" stroke=\"#9EAAB2\" d=\"M.5.5h11v11H.5z\"/><path stroke=\"#9EAAB2\" stroke-width=\".5\" d=\"M2.25 4.25h.5v.5h-.5zM4.25 4.25h5.5v.5h-5.5z\"/><path fill=\"#9EAAB2\" d=\"M1 0h10v2H1z\"/><path stroke=\"#9EAAB2\" stroke-width=\".5\" d=\"M2.25 6.25h.5v.5h-.5zM4.25 6.25h5.5v.5h-5.5zM2.25 8.25h.5v.5h-.5zM4.25 8.25h5.5v.5h-5.5z\"/><path d=\"M11.5 15.5a4 4 0 100-8 4 4 0 000 8z\" fill=\"#fff\" stroke=\"#9EAAB2\"/><path fill=\"#9EAAB2\" d=\"M11 9h1v3h-1z\"/><path fill=\"#9EAAB2\" d=\"M14 11v1h-3v-1z\"/>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});