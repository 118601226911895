define("ember-svg-jar/inlined/budget-in-progress", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\" transform=\"translate(.5 .5)\"><circle cx=\"14.5\" cy=\"14.5\" r=\"14.5\" fill=\"#FDD835\"/><path fill=\"#FFF\" d=\"M7.229 7.25H21.77c.79 0 1.429.64 1.429 1.429v.042c0 .79-.64 1.429-1.429 1.429H7.23c-.79 0-1.429-.64-1.429-1.429V8.68c0-.79.64-1.429 1.429-1.429zm0 5.8H21.77c.79 0 1.429.64 1.429 1.429v.042c0 .79-.64 1.429-1.429 1.429H7.23c-.79 0-1.429-.64-1.429-1.429v-.042c0-.79.64-1.429 1.429-1.429zm0 5.8h5.842c.79 0 1.429.64 1.429 1.429v.042c0 .79-.64 1.429-1.429 1.429H7.23c-.79 0-1.429-.64-1.429-1.429v-.042c0-.79.64-1.429 1.429-1.429z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "30",
      "height": "30",
      "viewBox": "0 0 30 30"
    }
  };
  _exports.default = _default;
});