define("ember-svg-jar/inlined/notification-warning", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#FFF\" fill-opacity=\".75\" d=\"M15.82 13.032c.513.874-.13 1.968-1.155 1.968H1.335C.309 15-.332 13.905.18 13.032L6.845 1.656c.513-.875 1.798-.874 2.31 0l6.665 11.376zM8 5a1.462 1.462 0 00-1.455 1.603l.277 2.846a1.184 1.184 0 002.356 0l.277-2.846A1.462 1.462 0 008 5zm0 8.74a1.074 1.074 0 100-2.148 1.074 1.074 0 000 2.148z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16"
    }
  };
  _exports.default = _default;
});