define("ember-svg-jar/inlined/download", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path class=\"fill-current\" fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M7 1h2v6.57l2.485-2.484L12.899 6.5l-3.535 3.535L7.95 11.45l-1.415-1.414L3 6.5l1.414-1.414L7 7.672V1z\" fill=\"#9EAAB2\"/><path class=\"fill-current\" fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M1 11h2v2h10v-2h2v4H1v-4z\" fill=\"#9EAAB2\"/>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});