define("ember-svg-jar/inlined/lock-large", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M8 1C5.515 1 3.5 2.88 3.5 5.2v1.4H2V15h12V6.6h-1.5V5.2C12.5 2.88 10.485 1 8 1zm1.5 5.6V5.2c0-.773-.672-1.4-1.5-1.4s-1.5.627-1.5 1.4v1.4h3z\" fill=\"#9EAAB2\" class=\"fill-current\"/>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});