define("ember-svg-jar/inlined/na-budgets", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g data-name=\"Layer 2\"><g data-name=\"Layer 1\"><path fill=\"#d83d00\" d=\"M36.05 45.86v.05h.08l-.08-.05z\"/><ellipse cx=\"35.5\" cy=\"55.43\" rx=\"31.95\" ry=\"18.44\" fill=\"#f2f5f9\"/><path fill=\"#dce2ea\" d=\"M35.5 16.97v20.5l25.18 14.54v-20.5L35.5 16.97z\"/><path d=\"M35.5 37.47L60.52 23c-13.9-8-36.38-8.07-50.2-.09A26 26 0 003.9 28L0 25.75v11.64c0 5.25 3.52 10.51 10.48 14.53 13.9 8 36.38 8.07 50.2.09z\" fill=\"#dce2ea\"/><path d=\"M60.52 11.3c-13.9-8-36.38-8.07-50.2-.09s-13.75 21 .16 29 36.38 8.07 50.2.09L35.5 25.75z\" fill=\"#e9edf4\"/><path d=\"M60.52 0l-25 14.45L60.68 29c6.94-4 10.37-9.29 10.32-14.55S67.45 4 60.52 0z\" fill=\"#cfd7e2\"/><path d=\"M71 14.49C71.05 19.76 67.62 25 60.68 29L35.5 14.45v11.64l25.18 14.54V52C67.6 48 71 42.77 71 37.51v-23z\" fill=\"#dce2ea\"/></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 71 73.86"
    }
  };
  _exports.default = _default;
});