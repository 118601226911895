define("ember-freestyle/templates/components/freestyle-palette-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5BV6I0gd",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"FreestylePaletteItem\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"FreestylePaletteItem-color\"],[15,5,[34,0]],[12],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"FreestylePaletteItem-info\"],[12],[2,\"\\n    \"],[10,\"p\"],[14,0,\"FreestylePaletteItem-hex\"],[12],[1,[35,1,[\"base\"]]],[13],[2,\"\\n    \"],[10,\"p\"],[14,0,\"FreestylePaletteItem-name\"],[12],[1,[35,1,[\"name\"]]],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"paletteStyle\",\"color\"]}",
    "moduleName": "ember-freestyle/templates/components/freestyle-palette-item.hbs"
  });

  _exports.default = _default;
});