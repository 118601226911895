define("@microstates/ember/helpers/state", ["exports", "@microstates/ember/index", "@microstates/ember/-private"], function (_exports, _index, _private) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    state: Ember.computed("typeOrValue", "value", {
      get() {
        let typeOrValue = this.get("typeOrValue");
        let value = this.get("value");
        let initial = (0, _private.stateFrom)(typeOrValue, value);
        return (0, _index.Store)(initial, state => this.set("state", state));
      },

      set(key, state) {
        return state;
      }

    }),
    // eslint-disable-next-line ember/no-observers
    stateDidChange: Ember.observer("state", function () {
      this.recompute();
    }),

    compute([typeOrValue, value]) {
      this.setProperties({
        typeOrValue,
        value
      });
      return this.get("state");
    }

  });

  _exports.default = _default;
});