define("ember-svg-jar/inlined/document-spinner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><g fill=\"#9EAAB2\"><path fill-rule=\"nonzero\" d=\"M21 11.828L18.172 9H11v12h10v-9.172zM11 7h8l4 4v10a2 2 0 01-2 2H11a2 2 0 01-2-2V9a2 2 0 012-2z\"/><path d=\"M17 7h2v6h-2z\"/><path d=\"M17 11h6v2h-6z\"/></g><path fill=\"#F2F5F9\" fill-rule=\"nonzero\" d=\"M9.551 28.846C1.898 25.676-1.737 16.9 1.434 9.247 4.604 1.593 13.378-2.04 21.032 1.13 28.686 4.3 32.32 13.074 29.15 20.728 25.98 28.38 17.205 32.016 9.55 28.846zm.766-1.848c6.633 2.747 14.238-.403 16.985-7.036C30.05 13.33 26.9 5.724 20.267 2.977 13.633.229 6.029 3.379 3.28 10.012c-2.747 6.634.403 14.238 7.036 16.986z\"/><path class=\"spinner-dash\" fill=\"#9EAAB2\" fill-rule=\"nonzero\" d=\"M21.093 1.155a1 1 0 11-.774 1.844 12.954 12.954 0 00-10.002-.022 1 1 0 11-.765-1.848 14.954 14.954 0 0111.54.026z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "31",
      "height": "30",
      "viewBox": "0 0 31 30"
    }
  };
  _exports.default = _default;
});