define("ember-svg-jar/inlined/share", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path class=\"fill-current\" d=\"M23.962 1.561a.503.503 0 00-.462-.31h-6.359a.5.5 0 000 1h5.152l-9.48 9.48a.5.5 0 00.708.707L23 2.958V8.11a.5.5 0 001 0V1.751a.5.5 0 00-.038-.19z\" fill=\"#010101\"/><path class=\"fill-current\" d=\"M20.004 11.317a.5.5 0 00-.5.5v10.932H2V5.244h11.054a.5.5 0 000-1H1.5a.5.5 0 00-.5.5v18.504a.5.5 0 00.5.5h18.504a.5.5 0 00.5-.5V11.817a.5.5 0 00-.5-.5z\" fill=\"#010101\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 25 25"
    }
  };
  _exports.default = _default;
});