define("ember-freestyle/components/freestyle-section", ["exports", "ember-freestyle/templates/components/freestyle-section"], function (_exports, _freestyleSection) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _freestyleSection.default,
    classNames: ['FreestyleSection'],
    classNameBindings: ['show:FreestyleSection--showing:FreestyleSection--hidden'],
    emberFreestyle: Ember.inject.service(),
    show: Ember.computed('emberFreestyle.section', 'name', function () {
      let focusedSection = this.get('emberFreestyle.section');
      return Ember.isBlank(focusedSection) || this.get('name') === focusedSection;
    }),
    showName: Ember.computed.and('emberFreestyle.notFocused', 'name'),
    hasName: Ember.computed.and('showName', 'name'),

    willRender() {
      this._super(...arguments);

      this.get('emberFreestyle').registerSection(this.get('name'));
    }

  });

  _exports.default = _default;
});