define("ember-svg-jar/inlined/download-box-spinner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><g transform=\"translate(7 6)\"><path stroke=\"#9EAAB2\" class=\"stroke-current\" d=\"M.5 4.111V15.5h14V4.111L12.807.5H2.193L.5 4.111z\"/><path fill=\"#9EAAB2\" class=\"fill-current\" d=\"M15 5H0l1.875-5h11.25z\"/><circle cx=\"7.5\" cy=\"4.5\" r=\"1.5\" fill=\"#9EAAB2\" class=\"fill-current\"/><g fill=\"#FFF\"><path d=\"M7.5 12h1V7h-1z\"/><path d=\"M10.829 10l-.707-.707-2.829 2.828.707.707z\"/><path d=\"M5.879 9.293L5.172 10 8 12.828l.707-.707z\"/></g><g fill=\"#9EAAB2\" class=\"fill-current\"><path d=\"M7 12h1V7H7z\"/><path d=\"M10.329 10l-.707-.707-2.829 2.828.707.707z\"/><path d=\"M5.379 9.293L4.672 10 7.5 12.828l.707-.707z\"/></g><path fill=\"#FFF\" d=\"M4 13h8v1H4z\"/><path fill=\"#9EAAB2\" class=\"fill-current\" d=\"M4 13h7v1H4z\"/></g><circle class=\"spinner-circle\" cx=\"14.5\" cy=\"14\" r=\"13\" stroke=\"#F2F5F9\" stroke-linecap=\"round\" stroke-width=\"2\"/><path class=\"spinner-dash stroke-current\" stroke=\"#9EAAB2\" stroke-dasharray=\"13 81.681\" stroke-linecap=\"round\" stroke-width=\"2\" d=\"M21 2.742C14.782-.848 6.832 1.282 3.242 7.5S1.782 21.668 8 25.258s14.168 1.46 17.758-4.758S27.218 6.332 21 2.742z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 29 28"
    }
  };
  _exports.default = _default;
});