define("ember-svg-jar/inlined/expand", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<defs><style>.cls-1{fill:#231f20}</style></defs><title>expand</title><g id=\"Layer_2\" data-name=\"Layer 2\"><g id=\"Layer_1-2\" data-name=\"Layer 1\"><path class=\"cls-1\" d=\"M.5 1h14a.5.5 0 000-1H.5a.5.5 0 000 1zM14.5 19H.5a.5.5 0 000 1h14a.5.5 0 000-1zM10.38 5.69l-2.5-3a.49.49 0 00-.74 0l-2.5 3a.5.5 0 10.77.64L7 4.38V9a.5.5 0 001 0V4.38l1.62 1.95a.5.5 0 10.77-.64zM7.5 10.5a.5.5 0 00-.5.5v4.62l-1.62-1.95a.5.5 0 10-.77.64l2.5 3a.49.49 0 00.12.09.48.48 0 00.41 0 .49.49 0 00.12-.09l2.5-3a.5.5 0 10-.77-.64L8 15.62V11a.5.5 0 00-.5-.5z\"/></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 15 20"
    }
  };
  _exports.default = _default;
});