define("ember-svg-jar/inlined/scatter-plot", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"#010101\"><path d=\"M22.459 23.5H1.378V3.407a.5.5 0 00-1 0V24.5h22.081a.5.5 0 000-1z\"/><circle cx=\"6.025\" cy=\"18.16\" r=\"1.842\"/><circle cx=\"12.884\" cy=\"16.319\" r=\"1.842\"/><circle cx=\"8.966\" cy=\"11.146\" r=\"1.842\"/><circle cx=\"13.224\" cy=\"2.842\" r=\"1.842\"/><circle cx=\"17.6\" cy=\"11.146\" r=\"1.842\"/><circle cx=\"22.842\" cy=\"8.282\" r=\"1.842\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 25 25"
    }
  };
  _exports.default = _default;
});