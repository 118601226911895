define("ember-svg-jar/inlined/email", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#fff\" stroke=\"#78909C\" class=\"stroke-current\" d=\"M1.5 4.5h17v11h-17z\"/><path d=\"M17.592 15.5H2.006L9.8 7.707l7.793 7.793z\" fill=\"#fff\" stroke=\"#78909C\" class=\"stroke-current\"/><path d=\"M17.93 5.375l-7.349 6.467a.5.5 0 01-.66 0L2.57 5.375A.5.5 0 012.9 4.5h14.7a.5.5 0 01.33.875z\" fill=\"#fff\" stroke=\"#78909C\" stroke-linejoin=\"round\" class=\"stroke-current\"/>",
    "attrs": {
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});