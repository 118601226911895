define("ember-basic-dropdown/templates/components/basic-dropdown-trigger", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Y3Et3wVN",
    "block": "{\"symbols\":[\"Element\",\"@defaultClass\",\"@vPosition\",\"@hPosition\",\"@renderInPlace\",\"@dropdown\",\"&attrs\",\"&default\",\"@htmlTag\"],\"statements\":[[6,[37,8],[[30,[36,7],[[30,[36,6],[[30,[36,5],[[32,9],\"div\"],null]],null]],[[\"tagName\"],[[30,[36,5],[[32,9],\"div\"],null]]]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,[32,1],[[16,0,[31,[\"ember-basic-dropdown-trigger\",[30,[36,1],[[32,5],\" ember-basic-dropdown-trigger--in-place\"],null],[30,[36,1],[[32,4],[30,[36,0],[\" ember-basic-dropdown-trigger--\",[32,4]],null]],null],[30,[36,1],[[32,3],[30,[36,0],[\" ember-basic-dropdown-trigger--\",[32,3]],null]],null],\" \",[32,2]]]],[24,\"role\",\"button\"],[16,\"tabindex\",[30,[36,2],[[32,6,[\"disabled\"]],\"0\"],null]],[16,\"data-ebd-id\",[31,[[32,6,[\"uniqueId\"]],\"-trigger\"]]],[16,\"aria-owns\",[31,[\"ember-basic-dropdown-content-\",[32,6,[\"uniqueId\"]]]]],[16,\"aria-expanded\",[30,[36,1],[[32,6,[\"isOpen\"]],\"true\"],null]],[16,\"aria-disabled\",[30,[36,1],[[32,6,[\"disabled\"]],\"true\"],null]],[17,7],[4,[38,3],[[32,0,[\"removeGlobalHandlers\"]]],null],[4,[38,4],[\"mousedown\",[32,0,[\"handleMouseDown\"]]],null],[4,[38,4],[\"click\",[32,0,[\"handleClick\"]]],null],[4,[38,4],[\"keydown\",[32,0,[\"handleKeyDown\"]]],null],[4,[38,4],[\"touchstart\",[32,0,[\"handleTouchStart\"]]],null],[4,[38,4],[\"touchend\",[32,0,[\"handleTouchEnd\"]]],null]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[18,8,null],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"concat\",\"if\",\"unless\",\"will-destroy\",\"on\",\"or\",\"-element\",\"component\",\"let\"]}",
    "moduleName": "ember-basic-dropdown/templates/components/basic-dropdown-trigger.hbs"
  });

  _exports.default = _default;
});