define("ember-svg-jar/inlined/transactions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"#231f20\"><path d=\"M23 4.5H2C1.173 4.5.5 5.173.5 6v13c0 .827.673 1.5 1.5 1.5h21c.827 0 1.5-.673 1.5-1.5V6c0-.827-.673-1.5-1.5-1.5zm-21 1h21a.5.5 0 01.5.5v2.5h-22V6a.5.5 0 01.5-.5zm21 14H2a.5.5 0 01-.5-.5v-8.5h22V19a.5.5 0 01-.5.5z\"/><path d=\"M16.5 16.5h5v1h-5z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 25 25"
    }
  };
  _exports.default = _default;
});