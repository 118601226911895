define("ember-freestyle/components/freestyle-palette-item", ["exports", "ember-freestyle/templates/components/freestyle-palette-item"], function (_exports, _freestylePaletteItem) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _freestylePaletteItem.default,
    tagName: '',
    // color - passed in
    paletteStyle: Ember.computed('color', function () {
      let color = this.get('color');
      return Ember.String.htmlSafe(`background-color: ${color.base};`);
    })
  });

  _exports.default = _default;
});