define("ember-svg-jar/inlined/chevron-double-up", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"#FFF\" fill-rule=\"evenodd\"><path d=\"M5.033 3.735L2.487 1.189l.88-.88 2.546 2.546z\"/><path d=\"M.087 2.855L2.633.309l.88.88L.967 3.735zM5.032 6.846L2.487 4.301l.88-.88 2.545 2.546z\"/><path d=\"M.087 5.965L2.632 3.42l.88.88L.966 6.844z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "6",
      "height": "7",
      "viewBox": "0 0 6 7"
    }
  };
  _exports.default = _default;
});