define("ember-svg-jar/inlined/reminder-enable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M0 0h16v16H0V0z\" fill=\"#fff\"/><path d=\"M13 10.3V6.5c0-2-1.3-3.6-3-4.2C9.9 1.6 9.2 1 8.5 1S7.1 1.6 7 2.3c-1.7.6-3 2.2-3 4.2v3.8L3 13h11l-1-2.7zM6 15h2c.6 0 1-.4 1-1H5c0 .6.4 1 1 1z\" fill=\"#78909C\"/>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});