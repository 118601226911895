define("ember-svg-jar/inlined/download-file-action", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><g fill=\"#FFF\"><path d=\"M10 15h1.538V7.5H10z\"/><path d=\"M15.066 12.054l-1.087-1.088-4.243 4.243 1.088 1.088z\"/><path d=\"M7.6 10.925l-1.087 1.088 4.243 4.243 1.087-1.088z\"/></g><rect width=\"20\" height=\"10.5\" y=\"7.5\" fill=\"#9EAAB2\" rx=\"1\"/><g fill=\"#FFF\"><path d=\"M9.231 15h1.538V7.5H9.231z\"/><path d=\"M14.297 12.054l-1.087-1.088-4.243 4.243 1.088 1.088z\"/><path d=\"M6.832 10.925l-1.088 1.088 4.243 4.243 1.087-1.088z\"/></g><path fill=\"#9EAAB2\" class=\"arrow-top-line\" d=\"M9.231 7.5h1.538V0H9.231z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "20",
      "height": "18",
      "viewBox": "0 0 20 18"
    }
  };
  _exports.default = _default;
});