define("ember-svg-jar/inlined/allovue-logo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" fill=\"#FFF\" d=\"M99.2 128H28.8C12.9 128 0 115.1 0 99.2V28.8C0 12.9 12.9 0 28.8 0h70.4C115.1 0 128 12.9 128 28.8v70.4c0 15.9-12.9 28.8-28.8 28.8z\"/><g fill-rule=\"evenodd\" clip-rule=\"evenodd\"><path fill=\"#D2409B\" d=\"M39.7 128h-11C12.9 128 0 115.1 0 99.3v-.2l39.7-18.5V128z\"/><path fill=\"#23ADCE\" d=\"M82.8 128H49.7V76l33.1-15.4z\"/><path fill=\"#BADD1D\" d=\"M128 39.6v59.8c0 15.8-12.9 28.7-28.7 28.7h-6.5V55.9L128 39.6z\"/><path fill=\"#4A4A4A\" d=\"M28.7 0h70.7C115.1 0 128 12.9 128 28.7v1.6L0 89.8V28.7C0 12.9 12.9 0 28.7 0z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 128 128"
    }
  };
  _exports.default = _default;
});