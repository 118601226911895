define("ember-inputmask/utils/compare-objects", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.areDifferent = areDifferent;

  function areDifferent(obj1, obj2) {
    return JSON.stringify(obj1) !== JSON.stringify(obj2);
  }
});