define("@microstates/ember/macros/state", ["exports", "@microstates/ember/index", "@microstates/ember/-private"], function (_exports, _index, _private) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = use;

  function use(typeOrValue, value) {
    return Ember.computed({
      get(key) {
        let initial = (0, _private.stateFrom)(typeOrValue, value);
        return (0, _index.Store)(initial, state => Ember.set(this, key, state));
      },

      set(key, state) {
        return state;
      }

    });
  }
});