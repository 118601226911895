define("ember-svg-jar/inlined/chat-inverse", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M22.345 10.07a4.858 4.858 0 01-3.278 4.581.654.654 0 00-.442.618v2.175l-2.423-2.341a.654.654 0 00-.455-.184h-1.922a4.854 4.854 0 01-4.848-4.849v-.9a4.854 4.854 0 014.848-4.849h3.671a4.854 4.854 0 014.85 4.849v.9zm-4.849-7.059h-2.042C14.428 1.055 12.104 0 9.726 0H6.054A6.061 6.061 0 000 6.055v.9c0 2.112.99 4.331 3.054 5.483v3.339a.55.55 0 00.932.396l3.121-3.163 1.384.125a6.158 6.158 0 005.334 3.094h1.658l3.342 3.229a.66.66 0 00.711.131c.243-.102.4-.34.4-.602v-3.271a6.164 6.164 0 003.72-5.646v-.9a6.167 6.167 0 00-6.16-6.159z\" fill=\"#fff\"/>",
    "attrs": {
      "width": "24",
      "height": "20",
      "viewBox": "0 0 24 20",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});