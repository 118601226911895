define("ember-svg-jar/inlined/no-transactions-data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g data-name=\"Layer 2\"><g data-name=\"Layer 1\"><path d=\"M59.81 51.1L41.77 40.68 23.72 30.27 5.79 40.62l18.04 10.42h.01L5.91 61.39l18.04 10.42 17.93-10.35v-.01L59.81 51.1zm18.04 10.42L59.81 51.1l17.93-10.35 18.05 10.42-17.94 10.35z\" fill=\"#f2f5f9\"/><path fill=\"#dce2ea\" d=\"M40.04 11.51H0v14.03l20.05 11.58 19.93-11.51-.13-.07h.19V11.51z\"/><path fill=\"#e9edf4\" d=\"M20.05 23.08L0 11.5 19.93 0l20.05 11.58-19.93 11.5z\"/><path fill=\"#dce2ea\" d=\"M60.02 37.19L39.97 25.61l-19.92 11.5L.19 39.34v9.35L20.05 60.2l39.97-23.01z\"/><path fill=\"#dce2ea\" d=\"M100 27.94l-39.98-.06V13.73H20.05v23.46h39.98l19.98 11.46 19.85-11.46h.07l.07-9.25z\"/><path fill=\"#e9edf4\" d=\"M80.04 39.37L60.02 27.83l19.9-11.54L100 27.9 80.04 39.37z\"/><path fill=\"#cfd7e2\" d=\"M40.1 25.3L20.05 13.73 39.97 2.22l20.05 11.51L40.1 25.3z\"/><path fill=\"#e9edf4\" d=\"M20.24 50.91L.19 39.34l19.93-11.51 20.05 11.58-19.93 11.5z\"/></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 100 71.81"
    }
  };
  _exports.default = _default;
});