define("ember-svg-jar/inlined/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g class=\"fill-current\" fill=\"#9EAAB2\" fill-rule=\"evenodd\"><path d=\"M4.88 11.95L2.05 9.12l7.78-7.777 2.828 2.828zM.637 13.363l.707-3.535 2.828 2.828zM13.365 3.464A2 2 0 1010.536.635l2.829 2.829z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "14",
      "height": "14",
      "viewBox": "0 0 14 14"
    }
  };
  _exports.default = _default;
});