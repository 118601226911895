define("ember-svg-jar/inlined/chevron-up-arrow-blue", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"#8DD2F7\" fill-rule=\"evenodd\"><path d=\"M8.536 6.413l-4.95-4.95L5.001.049l4.95 4.95z\"/><path d=\"M6.415 1.463l-4.95 4.95L.051 4.998 5 .048z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "10",
      "height": "7",
      "viewBox": "0 0 10 7"
    }
  };
  _exports.default = _default;
});