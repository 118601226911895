define("ember-svg-jar/inlined/bar-chart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"#010101\"><path d=\"M24 22.973H1.5V1.527a.5.5 0 00-1 0v22.446H24a.5.5 0 000-1z\"/><path d=\"M4 4h5v17H4zM11 9h5v12h-5zM18 13h5v8h-5z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 25 25"
    }
  };
  _exports.default = _default;
});