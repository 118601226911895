define("ember-element-resize-detector/services/resize-detector", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  const error = Ember.Logger.error;
  exports.default = Ember.Service.extend({

    init() {
      this._super(...arguments);
      this.detector = elementResizeDetectorMaker({
        strategy: "scroll"
      });
    },

    setup(selector, callback) {
      let element = document.querySelector(selector);
      if (!element) {
        error(`service:resize-detector - could not find an element matching ${selector}`);
        return;
      }
      this.detector.listenTo(element, callback);
    },

    teardown(selector, callback) {
      let element = document.querySelector(selector);
      if (element) {
        this.detector.removeListener(element, callback);
      }
    }

  });
});