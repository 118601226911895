define("ember-svg-jar/inlined/profile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M11.5 22.042c-2.591 0-4.964-.944-6.801-2.501a30.103 30.103 0 002.713-.902c.114-.045.231-.086.349-.128.83-.289 1.615-.563 1.635-2.564-.673-.921-.896-1.502-1.474-2.997l-.062-.16a.337.337 0 01-.245-.11c-.433-.307-.755-1.909-.76-2.361-.003-.246.05-.419.159-.53a.461.461 0 01.405-.114c-.01-.237-.044-.517-.081-.81-.076-.614-.154-1.25-.03-1.703.256-.927.602-2.182 1.464-2.284.599-.647 1.567-.975 2.879-.975.3 0 .552.018.714.029.066.005.116.009.144.009.559 0 1.688.798 2.353 1.514.466.503.506.654.673 1.289l.11.412c.129.469.048 1.117-.03 1.743-.036.28-.068.548-.079.77a.444.444 0 01.393.113c.108.11.161.283.158.528-.005.443-.311 1.984-.729 2.345-.034.067-.102.147-.264.135-.11.285-.201.534-.285.758-.386 1.046-.569 1.538-1.257 2.398.026 2.071 1.005 2.398 1.655 2.616.08.026.152.05.218.076.82.316 1.784.625 2.857.921a10.488 10.488 0 01-6.782 2.483M11.5 0C5.159 0 0 5.159 0 11.5S5.159 23 11.5 23 23 17.841 23 11.5 17.841 0 11.5 0\" fill=\"#90A4AE\" class=\"fill-current\"/>",
    "attrs": {
      "width": "23",
      "height": "23",
      "viewBox": "0 0 23 23",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});