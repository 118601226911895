define("ember-svg-jar/inlined/error-exclamation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill-rule=\"evenodd\"><circle cx=\"7.5\" cy=\"7.5\" r=\"7.5\"/><path fill=\"#FFF\" d=\"M7.5 2a1.5 1.5 0 011.496 1.607l-.345 4.84a1.153 1.153 0 01-2.301 0l-.346-4.84A1.5 1.5 0 017.5 2zm0 10.74a1.074 1.074 0 110-2.148 1.074 1.074 0 010 2.148z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "15",
      "height": "15",
      "viewBox": "0 0 15 15",
      "fill": "#e57373"
    }
  };
  _exports.default = _default;
});