define("ember-svg-jar/inlined/circle-plus", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path class=\"fill-current stroke-current\" d=\"M12.5.5C5.883.5.5 5.883.5 12.5s5.383 12 12 12 12-5.383 12-12-5.383-12-12-12zm0 23c-6.065 0-11-4.935-11-11s4.935-11 11-11 11 4.935 11 11-4.935 11-11 11z\" fill=\"#231f20\"/><path class=\"fill-current stroke-current\" d=\"M18.157 12H13V6.843a.5.5 0 00-1 0V12H6.843a.5.5 0 000 1H12v5.156a.5.5 0 001 0V13h5.157a.5.5 0 000-1z\" fill=\"#231f20\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 25 25"
    }
  };
  _exports.default = _default;
});