define("ember-svg-jar/inlined/file-csv-narrow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" class=\"group-hover:stroke-current group-focus:stroke-current\" clip-rule=\"evenodd\" d=\"M24.5 6.547L18.689 1H4a.5.5 0 00-.5.5v26a.5.5 0 00.5.5h20a.5.5 0 00.5-.5V6.547z\" stroke=\"#9EAAB2\"/><path d=\"M23 7.5h-5v-5\" class=\"fill-current\" stroke=\"#9EAAB2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path class=\"fill-current\" d=\"M22 12.5H1a1 1 0 00-1 1v8a1 1 0 001 1h21a1 1 0 001-1v-8a1 1 0 00-1-1z\" fill=\"#9EAAB2\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M9.08 16.395l-1.086.12c-.153-.603-.593-.913-1.153-.913-.801 0-1.341.654-1.341 1.997 0 1.344.54 1.98 1.34 1.98.56 0 .995-.303 1.154-.889l1.08.117C8.798 19.94 7.929 20.58 6.84 20.58c-1.435 0-2.443-1.108-2.443-2.98 0-1.878 1.008-2.998 2.443-2.998 1.088 0 1.966.645 2.239 1.793zm3.926-.065c-.052-.5-.404-.793-1.02-.793-.603 0-1.017.284-1.017.696 0 .4.426.596 1.102.815l.31.1c1.096.355 1.704.83 1.704 1.718 0 1.023-.798 1.722-2.133 1.722-1.17 0-2.2-.61-2.222-1.852h1.08c.042.605.508.92 1.167.92.716 0 1.043-.372 1.043-.77 0-.412-.332-.65-1.19-.914l-.324-.094c-.946-.281-1.611-.807-1.611-1.642 0-.978.898-1.634 2.1-1.634 1.207 0 2.042.688 2.062 1.728h-1.051zm2.872-1.648l1.312 4.37h.074l1.31-4.37h1.199L17.81 20.5h-1.17l-1.964-5.818h1.202z\" fill=\"#fff\"/>",
    "attrs": {
      "width": "25",
      "height": "29",
      "viewBox": "0 0 25 29",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});