define("ember-macro-helpers/computed-unsafe", ["exports", "ember-macro-helpers/-build-computed", "ember-macro-helpers/get-value-unsafe", "ember-macro-helpers/flatten-keys-unsafe"], function (_exports, _buildComputed, _getValueUnsafe, _flattenKeysUnsafe) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const collapseKeys = keys => keys;

  var _default = (0, _buildComputed.default)({
    collapseKeys,
    getValue: _getValueUnsafe.default,
    flattenKeys: _flattenKeysUnsafe.default
  });

  _exports.default = _default;
});