define("ember-toggle/components/x-toggle-label/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XquSRKvV",
    "block": "{\"symbols\":[\"&default\",\"&attrs\"],\"statements\":[[6,[37,2],[[32,0,[\"show\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[11,\"label\"],[16,\"for\",[32,0,[\"for\"]]],[16,0,[31,[\"toggle-text toggle-prefix \",[32,0,[\"labelType\"]]]]],[17,2],[4,[38,1],[\"click\",[30,[36,0],[[32,0],\"clickLabel\"],null]],null],[12],[2,\"\\n\"],[6,[37,2],[[27,[32,1]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[18,1,[[32,0,[\"label\"]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[1,[32,0,[\"label\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"on\",\"if\"]}",
    "moduleName": "ember-toggle/components/x-toggle-label/template.hbs"
  });

  _exports.default = _default;
});