define("ember-svg-jar/inlined/budget-needs-finalization", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\" transform=\"translate(0 .121)\"><circle cx=\"14.5\" cy=\"14.5\" r=\"14.5\" fill=\"#FFF\"/><path fill=\"#9575CD\" d=\"M8.7 27.793V7.23c0-.79-.64-1.429-1.429-1.429H7.23c-.79 0-1.429.64-1.429 1.429V26.1A14.478 14.478 0 010 14.5C0 6.492 6.492 0 14.5 0S29 6.492 29 14.5 22.508 29 14.5 29c-2.062 0-4.024-.43-5.8-1.207z\"/><path fill=\"#FFF\" d=\"M7.25 18.086a10.794 10.794 0 007.975 0 10.794 10.794 0 017.975 0V8.013a10.795 10.795 0 00-7.975 0 10.794 10.794 0 01-7.975 0v10.073z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "29",
      "height": "30",
      "viewBox": "0 0 29 30"
    }
  };
  _exports.default = _default;
});