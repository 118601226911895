define("ember-svg-jar/inlined/double-arrow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>double-arrow</title><g data-name=\"Layer 2\"><path d=\"M14.32 11.95a.49.49 0 00.18 0 .5.5 0 00.37-.16l5-5.49a.5.5 0 000-.67l-5-5.49A.5.5 0 0014 .5V3H5.5a.5.5 0 000 1h9a.5.5 0 00.5-.5V1.79L18.82 6 15 10.19V8.48a.5.5 0 00-.5-.5H6V5.52a.5.5 0 00-.87-.34l-5 5.49a.5.5 0 000 .67l5 5.49a.5.5 0 00.37.17.49.49 0 00.18 0 .5.5 0 00.32-.5V14h8.5a.5.5 0 000-1h-9a.5.5 0 00-.5.5v1.73L1.18 11 5 6.81v1.7a.5.5 0 00.5.5H14v2.48a.5.5 0 00.32.46z\" data-name=\"Layer 1\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 20 17"
    }
  };
  _exports.default = _default;
});