define("ember-svg-jar/inlined/hide-visualization", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path class=\"stroke-current\" d=\"M19.038 18.331c3.216-1.827 5.446-4.427 5.446-5.253 0-1.026-3.447-4.782-7.948-6.4l-.026-.017c-.044-.017-.673-.261-1.601-.464-.045-.01-.089-.021-.134-.03a12.294 12.294 0 00-2.275-.245c-.356 0-.697.025-1.028.058a12.236 12.236 0 00-1.226.184l-.196.044a12.988 12.988 0 00-1.051.28c-.299.096-.493.17-.516.179l-.015.01c-.264.095-.525.193-.782.302L2.854 2.146a.5.5 0 00-.707.707l4.572 4.572C3.103 9.248.516 12.189.516 13.077c0 1.279 5.829 7.156 11.984 7.156 1.986 0 3.909-.58 5.613-1.415l4.034 4.034a.502.502 0 00.707.002.5.5 0 000-.707l-3.816-3.816zm4.442-5.253c-.156.543-2.222 2.878-5.185 4.511l-1.93-1.93a5.986 5.986 0 001.46-7.34c3.215 1.634 5.493 4.188 5.655 4.759zM8.079 8.786l6.711 6.711a4.933 4.933 0 01-2.29.581c-2.757 0-5-2.243-5-5 0-.816.214-1.59.579-2.292zm.563-.851c.097-.119.182-.246.291-.357.445-.157.898-.296 1.363-.401.066-.015.138-.028.207-.042.275-.056.553-.104.834-.138.073-.009.15-.015.225-.022.31-.031.622-.053.937-.053.311 0 .618.022.923.051.083.008.169.015.25.025.259.031.515.075.769.125.102.02.205.039.299.06.284.064.565.136.842.22l.135.043c.114.036.225.08.338.119a4.957 4.957 0 011.445 3.513c0 1.561-.734 2.939-1.858 3.857l-7-7zM12.5 19.234c-5.769 0-10.825-5.415-10.986-6.127.106-.516 2.409-3.107 5.65-4.768a5.962 5.962 0 00-.664 2.739c0 3.309 2.691 6 6 6a5.96 5.96 0 003.037-.834l1.82 1.82c-1.478.69-3.13 1.17-4.857 1.17z\" fill=\"#231f20\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 25 25"
    }
  };
  _exports.default = _default;
});