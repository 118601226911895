define("ember-svg-jar/inlined/na-data-2", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g data-name=\"Layer 2\"><g data-name=\"Layer 1\"><path d=\"M65.93 83.43L5.87 48.86a1.74 1.74 0 010-3l26.46-15.29 61.8 35.57a1.74 1.74 0 010 3L69.4 83.43a3.47 3.47 0 01-3.47 0z\" fill=\"#f2f5f9\"/><path d=\"M67.7 69.42L1 31a1.93 1.93 0 010-3.34L28.45 11.8a3.84 3.84 0 013.85 0L99 50.21a1.93 1.93 0 010 3.34L71.55 69.42a3.84 3.84 0 01-3.85 0z\" fill=\"#e9edf4\"/><path fill=\"#dce2ea\" d=\"M59.83 9.45l-1.98-1.14 1.97-1.13 1.98 1.14-1.97 1.13z\"/><path d=\"M27.64 20.12S30.34-3.55 37.29.46 45.78 21.3 50.41 24s5-20.57 12-16.56 8.87 32.57 12 34.35 3.08-13.71 6.17-11.93 5 23.71 5 23.71z\" fill=\"#dce2ea\"/><path fill=\"#dce2ea\" d=\"M83.55 54.67l-1.98-1.14 1.62-2.34 2.33 2.34-1.97 1.14zM34.42 2.65L32.44 1.5 34.41.37l1.97 1.14-1.96 1.14zm45.29 29.38l-1.98-1.14 1.97-1.14 1.06.61-1.05 1.67z\"/><path d=\"M25.68 21.26s2.7-23.68 9.64-19.67 8.49 20.84 13.12 23.51 5-20.56 12-16.56 8.87 32.58 12 34.36 3.01-13.71 6.09-11.9 5 23.7 5 23.7z\" fill=\"#cfd7e2\"/><path d=\"M22.05 23.35s2.7-12.77 9.65-8.76 8.1 11 12.73 13.71 5.4-7.51 12.34-3.5 8.88 17.52 12 19.3 3.09-3.83 6.18-2.05 5 14.71 5 14.71z\" fill=\"#e9edf4\"/><path fill=\"#e9edf4\" d=\"M27.05 16.67l-1.98-1.14 1.97-1.14 1.98 1.15-1.97 1.13zm24.77 9.96l-1.98-1.14 1.97-1.14 1.98 1.14-1.97 1.14zm21.3 17.5l-1.98-1.14 1.96-1.13L75.08 43l-1.96 1.13zm4.84 13.77l-2.88-1.66 2.42-1.62 2.42 2.14-1.96 1.14z\"/><path d=\"M20.08 24.49s2.7-12.77 9.64-8.76 8.11 11 12.74 13.71 5.4-7.51 12.34-3.5 8.87 17.52 12 19.3 3.09-3.83 6.17-2.05S78 57.9 78 57.9z\" fill=\"#dce2ea\"/><path d=\"M16.46 26.57s3.78-5.65 14.58.59c7 4 8.54-2.6 16.26 1.86 6.94 4 10.06 15.86 16.92 19.81C72.3 53.5 74.33 60 74.33 60z\" fill=\"#e9edf4\"/><path fill=\"#e9edf4\" d=\"M18.15 27.44l-1.98-1.15 1.96-1.13 1.98 1.14-1.96 1.14zm54.21 33.68l-1.97-1.14-.78-3.67 4.72 3.68-1.97 1.13z\"/><path d=\"M14.48 27.71s3.79-5.65 14.59.59c7 4 8.54-2.59 16.26 1.86C52.27 34.17 55.39 46 62.24 50c8.08 4.67 10.12 11.16 10.12 11.16z\" fill=\"#dce2ea\"/></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 100 83.89"
    }
  };
  _exports.default = _default;
});