define("ember-freestyle/templates/components/freestyle-subsection", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/OJAV3Jt",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,2],[[35,3]],null,[[\"default\"],[{\"statements\":[[6,[37,2],[[35,1]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"FreestyleSubsection-name\"],[12],[2,\"\\n      \"],[1,[34,0]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"name\",\"hasName\",\"if\",\"show\"]}",
    "moduleName": "ember-freestyle/templates/components/freestyle-subsection.hbs"
  });

  _exports.default = _default;
});