define("ember-freestyle/templates/components/freestyle-section", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Mk/A7hR9",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,2],[[35,3]],null,[[\"default\"],[{\"statements\":[[6,[37,2],[[35,1]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"FreestyleSection-name\"],[12],[2,\"\\n      \"],[1,[34,0]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[18,1,[[30,[36,5],null,[[\"subsection\"],[[30,[36,4],[\"freestyle-subsection\"],[[\"section\"],[[35,0]]]]]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"name\",\"hasName\",\"if\",\"show\",\"component\",\"hash\"]}",
    "moduleName": "ember-freestyle/templates/components/freestyle-section.hbs"
  });

  _exports.default = _default;
});