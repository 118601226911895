define("ember-svg-jar/inlined/remove-x", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<circle cx=\"8\" cy=\"8\" r=\"8\" transform=\"rotate(-135 8 8)\" fill=\"#9EAAB2\" class=\"fill-current\"/><path fill=\"#fff\" d=\"M5.172 3.757l7.071 7.071-1.414 1.415-7.071-7.071z\"/><path fill=\"#fff\" d=\"M12.242 5.172l-7.071 7.07-1.414-1.414 7.07-7.07z\"/>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});