define("ember-svg-jar/inlined/bookmarks-cleared", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><path class=\"fill-current\" fill=\"#9EAAB2\" d=\"M11 8.2l.7-.7 1.8 1.8 1.8-1.8.7.7-1.8 1.8 1.8 1.8-.7.7-1.8-1.8-1.8 1.8-.7-.7 1.8-1.8L11 8.2z\"/><circle class=\"fill-none stroke-current\" stroke=\"#9EAAB2\" cx=\"13.5\" cy=\"10\" r=\"5\"/><path class=\"fill-none stroke-current\" stroke=\"#9EAAB2\" d=\"M8.5 10c0-2.8 2.2-5 5-5V3.5h-10V18l4.4-3.2c.4-.3.8-.3 1.2 0l4.4 3.2v-3c-2.8 0-5-2.2-5-5z\"/><path class=\"fill-none stroke-current\" stroke=\"#9EAAB2\" d=\"M10.5 3.5v-3H.5V15l3-2.2V3.5z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "19",
      "height": "19",
      "viewBox": "0 0 19 19"
    }
  };
  _exports.default = _default;
});